import React from "react";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import FallbackTickerIcon from "../../common/FallbackTickerIcon/FallbackTickerIcon";
import { makeStyles } from "@mui/styles";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import BeautifulSkeleton from "../../common/Skeletons/BeautifulSkeleton";
import CssPop from "../../common/Tables/animations/CssPop";

const useStyles = makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
      textAlign: "center",
      color: "black",
    },
    "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
      {
        "-webkit-appearance": "none",
        margin: 0,
      },
    "& .MuiOutlinedInput-root": {
      borderRadius: "4px",
      background: "rgba(0, 0, 0, 0.06)",
      border: "none",
      "&.Mui-focused fieldset": {
        border: "none",
      },
      "& fieldset": {
        border: "none",
      },
    },
  },
  removeIcon: {
    visibility: "hidden",
    cursor: "pointer",
    position: "absolute",
    left: "-14px",
    color: "red",
    transition: "visibility 0.4s",
    fontSize: "1.5rem",
  },
  tickerContainer: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    transition: "transform 0.4s",
    "&:hover": {
      transform: "translateX(30px)",
    },
    "&:hover $removeIcon": {
      visibility: "visible",
    },
  },
  liteStockPicksPortfolioRow: {
    position: "relative",
  },
});

const PortfolioRow = ({
  portfolio,
  onQtyChange,
  onRemove,
  userSettings,
  setUserSettings,
  setActiveTicker,
  handleSave,
  loading = false,
  isBlurred,
}) => {
  const classes = useStyles();
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.target.blur();
      handleSave();
    }
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;

    // If the input is empty, set quantity to 0
    if (inputValue === "") {
      onQtyChange(portfolio.ticker, 0);
      return;
    }

    // Ensure value is a number and restrict to 3 digits
    const newQty = parseInt(inputValue, 10);
    if (isNaN(newQty) || inputValue.length > 3) {
      return; // Prevent invalid or overly large input
    }

    // Update quantity for the specific ticker
    onQtyChange(portfolio.ticker, newQty);

    // Update user settings if `manualAdjust` is not already set
    if (!userSettings?.manualAdjust) {
      setUserSettings({
        ...userSettings,
        manualAdjust: true,
      });
    }
  };

  return (
    <div
      className={`${classes.liteStockPicksPortfolioRow} lite-stock-picks__portfolio_row `}
    >
      {loading ? (
        <>
          <div
            className={`${classes.tickerContainer} lite-stock-picks__portfolio_row_ticker`}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                cursor: "pointer",
              }}
            >
              <BeautifulSkeleton variant="circular" width={24} height={24} />
              <div className="lite-stock-picks__portfolio_row_name">
                <BeautifulSkeleton width={50} height={24} />
              </div>
            </div>
          </div>
          <div className="lite-stock-picks__portfolio_row_price">
            <BeautifulSkeleton width={100} height={24} />
          </div>
          <div className="lite-stock-picks__portfolio_row_change">
            <BeautifulSkeleton width={70} height={30} />
          </div>
        </>
      ) : (
        <>
          <div
            className={`${classes.tickerContainer} lite-stock-picks__portfolio_row_ticker`}
          >
            <RemoveCircleIcon
              className={classes.removeIcon}
              onClick={() => onRemove(portfolio.ticker)}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                cursor: "pointer",
              }}
              className={`${isBlurred ? "blurred" : ""}`}
              onClick={setActiveTicker}
            >
              <FallbackTickerIcon
                src={`https://assets.tradealgo.com/stock-logos/${portfolio.ticker}.png`}
                ticker={portfolio.ticker}
                className="lite-stock-picks__portfolio_row_icon"
              />
              <div className="lite-stock-picks__portfolio_row_name">
                {portfolio.ticker}
              </div>
            </div>
          </div>
          <div
            className={`lite-stock-picks__portfolio_row_price ${isBlurred ? "blurred" : ""}`}
          >
            <CssPop comparator={portfolio?.price} ticker={portfolio?.ticker} />
          </div>
          <div className="lite-stock-picks__portfolio_row_change">
            <TextField
              type="number"
              value={portfolio.qty}
              inputProps={{ min: 0, max: 100, step: 1 }}
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              variant="outlined"
              size="small"
              className={
                classes.input + " lite-stock-picks__portfolio_row_input"
              }
            />
          </div>
        </>
      )}
    </div>
  );
};

PortfolioRow.propTypes = {
  portfolio: PropTypes.shape({
    ticker: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    qty: PropTypes.number.isRequired,
  }).isRequired,
  onQtyChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default PortfolioRow;
