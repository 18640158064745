import React, { useCallback, useEffect, useMemo, useState } from "react";
import _get from "lodash/get";
import axios from "axios";
import Cookies from "universal-cookie";
import AuthContext from "./context";
import { analytics, auth } from "../../firebase/firebase";
import { apiURL } from "../../appRedux/actions/helpers";
import { useSnackbar } from "notistack";
import moment from "moment";
import firebase from "firebase/app";
import "firebase/auth";
import { useCookies } from "react-cookie";
const env = process.env["REACT_APP_ENV"];
const ssoAtStr = `sso_at_${env}`;
const sapStr = `sso_at_present_${env}`;
export function rmAllCookiesAndStorage() {
  console.log("removing all cookies -----");
  //clear all cookies and storage to avoid any token mixups
  localStorage.clear();
  const cookies = new Cookies();
  // Remove SSO at Cookie
  cookies.remove(ssoAtStr, {
    path: "/",
    domain: ".tradealgo.com",
    secure: true,
    sameSite: "strict",
  });
  cookies.remove(sapStr, {
    path: "/",
    domain: ".tradealgo.com",
    secure: true,
    sameSite: "strict",
  });
  cookies.remove("access_token", {
    path: "/",
    domain: ".tradealgo.com",
    secure: true,
    sameSite: "strict",
  });
}
function findData(user) {
  let displayName = "";

  let phoneNumber = "";
  if (user.providerData.length) {
    user.providerData.forEach((item) => {
      if (item.displayName) {
        displayName = item.displayName;
      }
      if (item.phoneNumber) {
        phoneNumber = item.phoneNumber;
      }
    });
  }
  return { displayName, phoneNumber };
}
const AuthProvider = ({ children }) => {
  const [dashboards, setDashboards] = useState([]);
  const [user, setUser] = useState(null);
  const [completeSignupMeta, setCompleteSignupMeta] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userDataLoading, setUserDataLoading] = useState(false);
  const [signoutLoading, setSignOutLoading] = useState(false);
  const [signInLoading, setSignInLoading] = useState(false);
  const [error, setError] = useState({
    message: "",
    isError: false,
    links: false,
  });
  const [cookies] = useCookies([ssoAtStr, sapStr]);
  const [fetching, setFetching] = useState(false);
  const [authToken, setAuthToken] = useState(
    localStorage.getItem("auth_token"),
  );
  const [userData, setUserData] = useState(null);
  const [userPinnedStocks, setUserPinnedStocks] = useState([]);
  const [authUser, setAuthUser] = useState(localStorage.getItem("user_id"));
  const [role, setRole] = useState("");
  const [checkoutSessionId, setCheckoutSessionId] = useState(null);
  // user subscription check
  const [isUserBasic, setIsUserBasic] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const tierLevel = useMemo(() => {
    // no flag has access to everything for now
    const today = moment();
    const isDefault = !userData?.feature_flags;
    if (loading || !userData) return 1;
    if (isDefault) return 1;
    // basic is restriced access
    const isBasic =
      !!userData?.feature_flags &&
      (userData?.feature_flags?.basic?.active || false) &&
      moment(today).isBefore(userData?.feature_flags?.basic?.expires);
    // premium has features yet to be developed
    const isPremium =
      !!userData?.feature_flags &&
      (userData?.feature_flags?.platinum?.active || false) &&
      moment(today).isBefore(userData?.feature_flags?.platinum?.expires);
    if (isPremium) return 2;
    if (isBasic) return 0;
    // if userData has not been fetched yet
    else return 1;
  }, [userData, loading]);
  const isDemo = useMemo(() => {
    const demoEmails = [
      "platform@tradealliance.io",
      "platform2@tradealliance.io",
    ];
    return (
      demoEmails.includes(user?.email) || demoEmails.includes(userData?.email)
    );
  }, [user?.email, userData?.email]);

  // options academy
  // const hasOptionsAcademy = false
  const hasOptionsAcademy = useMemo(() => {
    if (loading || !userData) return false;
    if (userData?.feature_flags?.options_academy?.active) return true;
    return false;
  }, [userData, loading]);

  // starter
  const hasOptionsMentorshipStarter = useMemo(() => {
    if (loading || !userData) return false;
    if (userData?.feature_flags?.options_mentorship_starter?.active)
      return true;
    return false;
  }, [userData, loading]);
  const mentorshipSessionsStarter = useMemo(() => {
    if (loading || !userData) return 0;
    return (
      userData?.feature_flags?.options_mentorship_starter
        ?.training_sessions_total || 0
    );
  }, [userData, loading]);

  // Intermediate
  const hasOptionsMentorshipIntermediate = useMemo(() => {
    if (loading || !userData) return false;
    if (userData?.feature_flags?.options_mentorship_intermediate?.active)
      return true;
    return false;
  }, [userData, loading]);
  const mentorshipSessionsIntermediate = useMemo(() => {
    if (loading || !userData) return 0;
    return (
      userData?.feature_flags?.options_mentorship_intermediate
        ?.training_sessions_total || 0
    );
  }, [userData, loading]);

  // Advanced
  const hasOptionsMentorshipAdvanced = useMemo(() => {
    if (loading || !userData) return false;
    if (userData?.feature_flags?.options_mentorship_advanced?.active)
      return true;
    return false;
  }, [userData, loading]);
  const mentorshipSessionsAdvanced = useMemo(() => {
    if (loading || !userData) return 0;
    return (
      userData?.feature_flags?.options_mentorship_advanced
        ?.training_sessions_total || 0
    );
  }, [userData, loading]);

  // wealth series
  const {
    hasWealthSeriesAtlas,
    hasWealthSeriesTitan,
    hasWealthSeriesEverest,
    hasWealthSeriesLite,
  } = useMemo(() => {
    if (loading || !userData)
      return {
        hasWealthSeriesAtlas: false,
        hasWealthSeriesTitan: false,
        hasWealthSeriesEverest: false,
        hasWealthSeriesLite: false,
      };
    const atlasActive = !!userData?.feature_flags?.wealth_series_atlas?.active;
    const titanActive = !!userData?.feature_flags?.wealth_series_titan?.active;
    const everestActive =
      !!userData?.feature_flags?.wealth_series_everest?.active;
    const liteActive = !!userData?.feature_flags?.wealth_series_lite?.active;

    return {
      hasWealthSeriesAtlas: atlasActive,
      hasWealthSeriesTitan: titanActive,
      hasWealthSeriesEverest: everestActive,
      hasWealthSeriesLite: liteActive,
    };
  }, [userData, loading]);

  const hasWealth = useMemo(
    () =>
      hasWealthSeriesAtlas ||
      hasWealthSeriesTitan ||
      hasWealthSeriesEverest ||
      hasWealthSeriesLite,
    [
      hasWealthSeriesAtlas,
      hasWealthSeriesTitan,
      hasWealthSeriesEverest,
      hasWealthSeriesLite,
    ],
  );

  // platinum
  const hasPlatinumProgram = useMemo(() => {
    if (loading || !userData) return false;
    if (userData?.feature_flags?.platinum?.active) return true;
    return false;
  }, [userData, loading]);
  const mentorshipSessionsPlatinum = useMemo(() => {
    if (loading || !userData) return 0;
    return userData?.feature_flags?.platinum?.training_sessions_total || 0;
  }, [userData, loading]);
  // emarld
  const hasEmerald = useMemo(() => {
    if (loading || !userData) return false;
    if (userData?.feature_flags?.emerald?.active) return true;
    return false;
  }, [userData, loading]);

  const mentorshipSessionsEmerald = useMemo(() => {
    if (loading || !userData) return 0;
    return userData?.feature_flags?.emerald?.training_sessions_total || 0;
  }, [userData, loading]);
  // shared
  // Determine if a user can access internal pages based on their subscription status and feature flags
  const canSeeInternal = useMemo(() => {
    // Check if user data is loading or not present, or if feature flags are not defined or empty
    if (
      loading ||
      !userData ||
      !userData.feature_flags ||
      !Object.keys(userData?.feature_flags || {}).length
    ) {
      // Log for debugging: No user data or feature flags found
      console.log("0-------------");
      return false;
    }
    // Allow access if the user has any mentorship package or the platinum program
    if (
      hasPlatinumProgram ||
      hasOptionsMentorshipAdvanced ||
      hasOptionsMentorshipIntermediate ||
      hasOptionsMentorshipStarter ||
      hasEmerald
    ) {
      // Log for debugging: User has access through mentorship or platinum program
      console.log("1-------------");
      return true;
    }
    // Allow access if the user's default feature flag is active
    if (userData?.feature_flags?.default?.active) {
      // Log for debugging: User has access through default feature flag
      console.log("2-------------");
      return true;
    }
    // Allow access if the user has an active TradeGPT premium subscription
    if (userData?.feature_flags?.tradegpt_premium?.active) {
      // Log for debugging: User has access through TradeGPT premium subscription
      console.log("2.5-------------");
      return true;
    }
    // Allow access if the user has an active Terminal Plus subscription
    if (userData?.feature_flags?.terminal_plus?.active) {
      // Log for debugging: User has access through Terminal Plus subscription
      console.log("3-------------");
      return true;
    }
    // Deny access if the user's base feature flag exists but is inactive
    if (
      !!userData.feature_flags?.default &&
      !userData?.feature_flags?.default?.active
    ) {
      // Log for debugging: User's base feature flag is inactive
      console.log("5-------------");
      return false;
    }
    // Default to false if none of the above conditions are met
    return false;
  }, [
    loading,
    userData,
    hasPlatinumProgram,
    hasOptionsMentorshipAdvanced,
    hasOptionsMentorshipIntermediate,
    hasOptionsMentorshipStarter,
    hasEmerald,
  ]);
  const mentorshipSessionsUsed = useMemo(() => {
    const init = {
      options_mentorship_starter: 0,
      options_mentorship_platinum: 0,
      options_mentorship_intermediate: 0,
      options_mentorship_advanced: 0,
      options_mentorship_emerald: 0,
      wealth_mentorship_atlas: 0,
      wealth_mentorship_titan: 0,
      wealth_mentorship_everst: 0,
    };
    if (loading || !userData) return init;
    return userData?.training_sessions_used || init;
  }, [userData, loading]);

  const sessionsLeftWealth = useMemo(() => {
    if (loading || !userData)
      return { atlas: 0, titan: 0, everest: 0, lite: 0 };

    const atlas =
      (userData?.feature_flags?.wealth_series_atlas?.training_sessions_total ||
        0) - (mentorshipSessionsUsed?.wealth_mentorship_atlas || 0);
    const titan =
      (userData?.feature_flags?.wealth_series_titan?.training_sessions_total ||
        0) - (mentorshipSessionsUsed?.wealth_mentorship_titan || 0);
    const everest =
      (userData?.feature_flags?.wealth_series_everest
        ?.training_sessions_total || 0) -
      (mentorshipSessionsUsed?.wealth_mentorship_everest || 0);
    const lite =
      (userData?.feature_flags?.wealth_series_lite?.training_sessions_total ||
        0) - (mentorshipSessionsUsed?.wealth_mentorship_lite || 0);

    return { atlas, titan, everest, lite };
  }, [loading, userData, mentorshipSessionsUsed]);

  const showCTABanner = useMemo(() => {
    const hasAnyMentorshipTier =
      hasPlatinumProgram ||
      hasOptionsMentorshipAdvanced ||
      hasOptionsMentorshipIntermediate ||
      hasOptionsMentorshipStarter;
    const starterSessions =
      (userData?.feature_flags?.options_mentorship_starter
        ?.training_sessions_total || 0) -
        mentorshipSessionsUsed?.options_mentorship_starter || 0;
    const intermediateSessions =
      (userData?.feature_flags?.options_mentorship_intermediate
        ?.training_sessions_total || 0) -
        mentorshipSessionsUsed?.options_mentorship_intermediate || 0;
    const advancedSessions =
      (userData?.feature_flags?.options_mentorship_advanced
        ?.training_sessions_total || 0) -
        mentorshipSessionsUsed?.options_mentorship_advanced || 0;
    const platinumSessions =
      (userData?.feature_flags?.platinum?.training_sessions_total || 0) -
        mentorshipSessionsUsed?.options_mentorship_platinum || 0;
    const sessionsTotalLeft =
      starterSessions +
      intermediateSessions +
      advancedSessions +
      platinumSessions;
    return hasAnyMentorshipTier && !!sessionsTotalLeft;
  }, [
    hasPlatinumProgram,
    hasOptionsMentorshipAdvanced,
    hasOptionsMentorshipIntermediate,
    hasOptionsMentorshipStarter,
    userData,
    loading,
    mentorshipSessionsUsed,
  ]);

  const CTABannerLink = useMemo(() => {
    const hasAnyMentorshipTier =
      hasPlatinumProgram ||
      hasOptionsMentorshipAdvanced ||
      hasOptionsMentorshipIntermediate ||
      hasOptionsMentorshipStarter;
    const platinumSessions =
      (userData?.feature_flags?.platinum?.training_sessions_total || 0) -
        mentorshipSessionsUsed?.options_mentorship_platinum || 0;
    const intermediateSessions =
      (userData?.feature_flags?.options_mentorship_intermediate
        ?.training_sessions_total || 0) -
        mentorshipSessionsUsed?.options_mentorship_intermediate || 0;
    const advancedSessions =
      (userData?.feature_flags?.options_mentorship_advanced
        ?.training_sessions_total || 0) -
        mentorshipSessionsUsed?.options_mentorship_advanced || 0;
    const starterSessions =
      (userData?.feature_flags?.options_mentorship_starter
        ?.training_sessions_total || 0) -
        mentorshipSessionsUsed?.options_mentorship_starter || 0;
    if (hasAnyMentorshipTier) {
      if (platinumSessions > 0) return "/options-mentorship/platinum";
      if (advancedSessions > 0) return "/options-mentorship/advanced";
      if (intermediateSessions > 0) return "/options-mentorship/intermediate";
      if (starterSessions > 0) return "/options-mentorship/starter";
    }
  }, [
    hasPlatinumProgram,
    hasOptionsMentorshipAdvanced,
    hasOptionsMentorshipIntermediate,
    hasOptionsMentorshipStarter,
    userData,
    loading,
    mentorshipSessionsUsed,
  ]);

  const emeraldPath = useMemo(() => {
    return hasEmerald ? "/emerald/tutorials" : "/emerald-signup";
  }, [loading, hasEmerald]);

  const platinumProgramPath = useMemo(() => {
    let path;
    path = `/platinum-program/${hasPlatinumProgram ? "livestream" : userData?.platinum_sign_up ? "thank-you" : "sign-up"}`;
    return path;
  }, [hasPlatinumProgram, loading, userData]);

  const hasSessionsLeftStarter = useMemo(() => {
    const starterSessionsUsed =
      mentorshipSessionsUsed?.options_mentorship_starter || 0;
    return mentorshipSessionsStarter - starterSessionsUsed > 0;
  }, [mentorshipSessionsStarter, mentorshipSessionsUsed]);

  const optionsMentorshipPath = useMemo(() => {
    let path = "plans";
    if (
      hasPlatinumProgram ||
      hasOptionsMentorshipAdvanced ||
      hasOptionsMentorshipIntermediate ||
      hasOptionsMentorshipStarter ||
      hasEmerald
    ) {
      path = "/options-mentorship/overview";
    } else {
      path = "/options-mentorship/plans";
    }
    return path;
  }, [hasSessionsLeftStarter, loading, userData]);

  const optionsAcademyPath = useMemo(() => {
    let path;
    // if they have a membership and sessions left or they have the membership and they did not renew yet
    if (hasOptionsAcademy || (hasOptionsAcademy && !userData?.oa_renewed)) {
      path = "/options-academy/courses/fundamentals";
      // otherwise
    } else {
      // did they renew or  sign up
      if (userData?.oa_renewed || userData?.oa_sign_up) {
        // take them to thank you page
        path = "/options-academy-thankyou";
      } else {
        // they havent been taken to the thank you page and have no feature flags, take them to sign up page
        path = "/options-academy-signup";
      }
    }
    return path;
  }, [hasOptionsAcademy, loading, userData]);

  const setClaims = useCallback(async () => {
    try {
      const result = await auth.currentUser.getIdTokenResult();

      if (result.claims.isAdmin) {
        setRole("admin");
      } else {
        setRole("customer");
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        setClaims();
      } else {
        // removeAuth();
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, [setClaims]);

  const setAuth = useCallback((token, user, email) => {
    setAuthToken(token);
    setAuthUser(user);

    localStorage.setItem("user_id", user);
    localStorage.setItem("auth_token", token);
    localStorage.setItem("email", email);
  }, []);

  const removeAuth = useCallback(() => {
    setAuthToken(null);
    setAuthUser(null);
    setRole(null);

    localStorage.removeItem("user_id");
    localStorage.removeItem("auth_token");
  }, []);

  const getIdToken = useCallback(() => {
    try {
      return auth.currentUser.getIdToken();
    } catch (error) {
      return error;
    }
  }, []);

  const getRefreshToken = useCallback(() => {
    try {
      return auth.currentUser.getIdToken(true);
    } catch (error) {
      return error;
    }
  }, []);

  const handleErrorMessages = useCallback((error) => {
    const connectionError = error.message.includes(
      "The database connection is closing",
    );

    if (connectionError) {
      setError({
        message:
          "Your session timed out. Please refresh the browser and try again.",
        isError: true,
        links: true,
      });
      enqueueSnackbar(
        "Your session timed out. Please refresh the browser and try again.",
        { variant: "error" },
      );
    } else {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }, []);

  const checkoutSessionRequest = useCallback(
    async (email, phone, firstName, lastName, user, couponId) => {
      try {
        const response = await axios.post(
          `${apiURL}/checkout`,
          {
            email: email,
            phone: phone,
            user_id: user,
            plan: couponId,
            firstName: firstName,
            lastName: lastName,
            utm_source: localStorage.getItem("utm_source"),
            utm_campaign: localStorage.getItem("utm_campaign"),
            utm_term: localStorage.getItem("utm_term"),
            utm_content: localStorage.getItem("utm_content"),
          },
          { withCredentials: true },
        );

        setCheckoutSessionId(response.data.session.id);
      } catch (error) {
        return error;
      }
    },
    [],
  );

  const authenticateRequest = useCallback(async (tokenId) => {
    const cookies = new Cookies();

    try {
      const response = await axios.post(
        `${apiURL}/authenticate`,
        {
          token: tokenId,
        },
        { withCredentials: true },
      );

      // Use optional chaining to check for the success in the response
      const success = response?.data?.success;
      if (success) {
        const ssoToken = response?.data[ssoAtStr];
        // Set a cookie with the token using universal-cookie
        cookies.set(ssoAtStr, ssoToken, {
          domain: ".tradealgo.com",
          path: "/",
          secure: true,
          sameSite: "strict",
        });
      }

      return response;
    } catch (error) {
      return error;
    }
  }, []);

  const authenticate = useCallback(async () => {
    try {
      setFetching(true);
      await getRefreshToken();

      const tokenId = await getIdToken();
      const user = auth.currentUser.uid;
      const email = auth.currentUser.email;
      const tokenResult = await authenticateRequest(tokenId);

      if (tokenResult.data.error_code) {
        throw new Error(tokenResult.data.message);
      }

      setAuth(tokenId, user, email);
    } catch (error) {
      setError({ ...error, isError: true });
      removeAuth();
      enqueueSnackbar(error.message, { variant: "error" });
      // message.error(error.message);
    } finally {
      setFetching(false);
    }
  }, [authenticateRequest, getIdToken, getRefreshToken, removeAuth, setAuth]);

  const fetchUser = useCallback(async () => {
    try {
      setUserDataLoading(true);
      setLoading(true);
      const response = await axios.get(`${apiURL}/user`, {
        withCredentials: true,
      });
      if (response.status === 200) {
        // setIsUserBasic(response.data.user.user_type !== "prime");
        setIsUserBasic(false);
        setUserData(response.data.user);
        analytics.setUserProperties({
          crm_id: response.data.user.userId,
        });
        analytics.setUserId(response.data.user.userId);
        if (_get(response, "data.pinnedStocks.length") > 1) {
          const stocks = response.data.pinnedStocks.map(
            (pinnedStock) => pinnedStock.ticker,
          );
          setUserPinnedStocks(stocks);
        }
        setDashboards(response.data.dashboards);
        setLoading(false);
        const cookies = new Cookies();
        const ssoToken = response?.data?.customToken;
        if (ssoToken) {
          cookies.set(ssoAtStr, ssoToken, {
            domain: ".tradealgo.com",
            path: "/",
            secure: true,
            sameSite: "strict",
          });
        }
        setUserDataLoading(false);
        return true;
      }
    } catch (error) {
      setUserDataLoading(false);
      setLoading(false);
      return error;
    }
  }, []);

  const signIn = useCallback(
    async (data) => {
      try {
        setLoading(true);
        setSignInLoading(true);
        const response = await auth.signInWithEmailAndPassword(
          data.email,
          data.password,
        );

        const tokenId = await getIdToken();
        const tokenResult = await authenticateRequest(tokenId);
        // if the sign in was a success and the user has signed in < 10 times
        if (
          !!response?.user?.uid &&
          Number(response?.user?.sign_in_attempts || 0) < 10
        ) {
          // log his sign in attempt
          await axios.get(`${apiURL}/signed_in_log`, { withCredentials: true });
        }
        if (tokenResult.data.error_code) {
          if (
            tokenResult.data.error_code === "PAYMENT_INCOMPLETE" ||
            tokenResult.data.error_code === "USER_NOT_FOUND"
          ) {
            setAuthUser(response.user.uid);
            return await checkoutSessionRequest(
              data.email,
              null,
              null,
              null,
              response.user.uid,
            );
          } else {
            throw new Error(tokenResult.data.message);
          }
        }

        await getRefreshToken();
        await setClaims();
        await fetchUser();
        setAuth(tokenId, response.user.uid, response.user.email);
        setSignInLoading(false);
        // Record successful auth
      } catch (error) {
        console.error(error);
        setLoading(false);
        setSignInLoading(false);
        const customErrorMessage = `Incorrect Email or Password`;
        enqueueSnackbar(customErrorMessage, { variant: "error" });
      }
    },
    [
      authenticateRequest,
      checkoutSessionRequest,
      getIdToken,
      getRefreshToken,
      setAuth,
      setClaims,
      fetchUser,
      enqueueSnackbar,
    ],
  );

  const signInWithProvider = useCallback(
    async (provider) => {
      try {
        setSignInLoading(true);
        rmAllCookiesAndStorage();
        let authProvider;
        switch (provider) {
          case "google":
            authProvider = new firebase.auth.GoogleAuthProvider();
            break;
          case "microsoft":
            authProvider = new firebase.auth.OAuthProvider("microsoft.com");
            break;
          case "apple":
            authProvider = new firebase.auth.OAuthProvider("apple.com");
            break;
          default:
            throw new Error("Unsupported provider");
        }
        const result = await firebase.auth().signInWithPopup(authProvider);
        // The rest of your logic...
        const tokenId = await getIdToken();
        await authenticateRequest(tokenId);
        const user = result.user;
        const uid = user.uid;
        const email = user?.email || "";
        let phone = user?.phoneNumber || "";
        let name = user?.displayName || "";
        let { phoneNumber, displayName } = findData(user);
        if (displayName?.length > name?.length) {
          name = displayName;
        }
        if (phone?.length < phoneNumber?.length) {
          phone = phoneNumber;
        }

        await getRefreshToken();
        await setClaims();
        setAuth(tokenId, uid, email);
        setSignInLoading(false);
        // Record successful auth
      } catch (error) {
        setLoading(false);
        console.log(error);
        handleErrorMessages(error);
        setSignInLoading(false);
      }
    },
    [
      authenticateRequest,
      checkoutSessionRequest,
      getIdToken,
      getRefreshToken,
      handleErrorMessages,
      setAuth,
      setClaims,
      fetchUser,
    ],
  );
  const [tokenLoading, setTokenLoading] = useState(false);
  const handleSignInWithToken = useCallback(async () => {
    if (!!cookies[sapStr] && !userData && !tokenLoading) {
      try {
        setTokenLoading(true);
        const res = await axios.get(`${apiURL}/user/auth-token`, {
          withCredentials: true,
        });
        const { data } = res;
        const token = data?.token;
        const userCredential = await auth.signInWithCustomToken(token);
        setAuth(token, userCredential.user.uid, userCredential.user.email);
        setTokenLoading(false);
      } catch (err) {
        rmAllCookiesAndStorage();
        console.log(err, "TA SSO failed");
      }
    }
  }, [cookies, userData]);

  useEffect(() => {
    handleSignInWithToken();
  }, [handleSignInWithToken, cookies]);
  const signUp = useCallback(
    async (data) => {
      try {
        setLoading(true);
        const response = await auth.createUserWithEmailAndPassword(
          data.email,
          data.password,
        );
        await axios.post(
          `${apiURL}/signup`,
          {
            userId: response.user.uid,
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            phoneNumber: data.phoneNumber,
          },
          { withCredentials: true },
        );

        const tokenId = await getIdToken();
        await authenticateRequest(tokenId);

        await getRefreshToken();
        await setClaims();
        await fetchUser();

        setAuth(tokenId, response.user.uid, response.user.email);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        enqueueSnackbar(error.message, { variant: "error" });
      }
    },
    [
      authenticateRequest,
      fetchUser,
      getIdToken,
      getRefreshToken,
      setAuth,
      setClaims,
    ],
  );

  const completeSignup = useCallback(
    async (data) => {
      try {
        setLoading(true);

        const response = await axios.post(
          `${apiURL}/complete-signup`,
          {
            email: data.email,
            password: data.password,
          },
          { withCredentials: true },
        );

        if (response.data.error) {
          throw new Error(response.data.error);
        }

        if (response.data.success) {
          setLoading(false);
          setCompleteSignupMeta(response.data.userMeta);
          return true;
        }

        return false;
      } catch (error) {
        setLoading(false);
        handleErrorMessages(error);
        return false;
      }
    },
    [handleErrorMessages],
  );
  const fullSignOut = async () => {
    removeAuth();
    rmAllCookiesAndStorage();
    setIsUserBasic(false);
    setUser(null);
    setUserPinnedStocks([]);
    await auth.signOut();
    setSignOutLoading(false);
    setUserData(null);
  };
  const signOut = useCallback(async () => {
    try {
      setSignOutLoading(true);
      if (userData) {
        await axios.post(`${apiURL}/signout`, {}, { withCredentials: true });
      }
      fullSignOut();
      // window.location.href = "https://tradealgo.com";
    } catch (error) {
      fullSignOut();
      handleErrorMessages(error);
    }
  }, [handleErrorMessages, removeAuth, userData]);
  useEffect(() => {
    if (
      authUser &&
      authToken &&
      !userData &&
      !userDataLoading &&
      !signoutLoading
    ) {
      fetchUser();
    }
  }, [authUser, authToken, userData, userDataLoading, signoutLoading]);
  const sendPasswordResetEmail = useCallback(async (email) => {
    try {
      let actionCodeSettings = {
        url: `http://${process.env.REACT_APP_REDIRECT_DOMAIN}/signin`,
        handleCodeInApp: false,
      };
      await auth.sendPasswordResetEmail(email, actionCodeSettings);

      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }, []);

  const updateUser = useCallback((newUserData) => {
    setUserData(newUserData);
  }, []);

  const contextValue = useMemo(
    () => ({
      loading,
      signInLoading,
      fetching,
      error,
      authToken,
      authUser,
      user,
      userData,
      userPinnedStocks,
      dashboards,
      role,
      completeSignupMeta,
      checkoutSessionId,
      signIn,
      signUp,
      completeSignup,
      signOut,
      authenticate,
      sendPasswordResetEmail,
      fetchUser,
      updateUser,
      isUserBasic,
      setIsUserBasic,
      tierLevel,
      hasOptionsMentorshipStarter,
      mentorshipSessionsStarter,
      mentorshipSessionsUsed,
      hasPlatinumProgram,
      mentorshipSessionsPlatinum,
      hasOptionsMentorshipIntermediate,
      mentorshipSessionsIntermediate,
      hasOptionsMentorshipAdvanced,
      mentorshipSessionsAdvanced,
      mentorshipSessionsEmerald,
      showCTABanner,
      CTABannerLink,
      hasOptionsAcademy,
      canSeeInternal,
      signInWithProvider,
      setAuth,
      hasEmerald,
      emeraldPath,
      platinumProgramPath,
      optionsMentorshipPath,
      optionsAcademyPath,
      userDataLoading,
      signoutLoading,
      hasWealth,
      hasWealthSeriesAtlas,
      hasWealthSeriesTitan,
      hasWealthSeriesEverest,
      hasWealthSeriesLite,
      sessionsLeftWealth,
      isDemo,
    }),
    [
      loading,
      signInLoading,
      fetching,
      error,
      authToken,
      authUser,
      user,
      userData,
      userPinnedStocks,
      dashboards,
      role,
      completeSignupMeta,
      checkoutSessionId,
      signIn,
      signUp,
      completeSignup,
      signOut,
      authenticate,
      sendPasswordResetEmail,
      fetchUser,
      updateUser,
      isUserBasic,
      setIsUserBasic,
      tierLevel,
      hasOptionsMentorshipStarter,
      mentorshipSessionsStarter,
      mentorshipSessionsUsed,
      hasPlatinumProgram,
      mentorshipSessionsPlatinum,
      hasOptionsMentorshipIntermediate,
      mentorshipSessionsIntermediate,
      hasOptionsMentorshipAdvanced,
      mentorshipSessionsAdvanced,
      mentorshipSessionsEmerald,
      showCTABanner,
      CTABannerLink,
      hasOptionsAcademy,
      canSeeInternal,
      signInWithProvider,
      setAuth,
      hasEmerald,
      emeraldPath,
      platinumProgramPath,
      optionsMentorshipPath,
      optionsAcademyPath,
      userDataLoading,
      signoutLoading,
      hasWealth,
      hasWealthSeriesAtlas,
      hasWealthSeriesTitan,
      hasWealthSeriesEverest,
      hasWealthSeriesLite,
      sessionsLeftWealth,
      isDemo,
    ],
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
