import { all, put, takeEvery, fork } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";
// Action Types
export const STOCK_RECOMMENDATIONS_LOAD = "STOCK_RECS/LOAD";
export const STOCK_RECOMMENDATIONS_LOAD_SUCCESS = "STOCK_RECS/LOAD/SUCCESS";
export const STOCK_RECOMMENDATIONS_LOAD_FAILURE = "STOCK_RECS/LOAD/FAILURE";

// Action Creators
export const stockRecommendationsLoad = () => ({
  type: STOCK_RECOMMENDATIONS_LOAD,
});
export const stockRecommendationsLoadSuccess = (data) => ({
  type: STOCK_RECOMMENDATIONS_LOAD_SUCCESS,
  data,
});
export const stockRecommendationsLoadFailure = (error) => ({
  type: STOCK_RECOMMENDATIONS_LOAD_FAILURE,
  error,
});

// Sagas
function* fetchStockRecommendations() {
  try {
    const response = yield axios.get(
      `${apiURL}/wealth-series-lite/stock-recommendations`,
      {
        withCredentials: true,
      },
    );
    yield put(stockRecommendationsLoadSuccess(response.data));
  } catch (error) {
    yield put(stockRecommendationsLoadFailure(error));
  }
}

function* listenStockRecommendationsLoad() {
  yield takeEvery(STOCK_RECOMMENDATIONS_LOAD, fetchStockRecommendations);
}

// Root Saga
export function* saga() {
  yield all([fork(listenStockRecommendationsLoad)]);
}

const INIT_STATE = {
  stockRecommendations: [],
  loading: true,
  error: null,
};
// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case STOCK_RECOMMENDATIONS_LOAD:
      return {
        ...state,
        loading: true,
      };
    case STOCK_RECOMMENDATIONS_LOAD_SUCCESS:
      return {
        ...state,
        stockRecommendations: action.data,
        loading: false,
      };
    case STOCK_RECOMMENDATIONS_LOAD_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
