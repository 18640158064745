import React, { useEffect, useMemo, useState } from "react";
import "./WealthSeriesPortfolioPerformanceGraph.scss";
import SparkLines from "../../../common/SparkLines";
import { dummyOptions } from "../../../TradingView/helpers";
import { connect } from "react-redux";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { portfolioPerformanceLoad } from "../../../../appRedux/ducks/wealthPerformance";
import moment from "moment";
import { useParams } from "react-router-dom";
import { init_portfolio } from "../../../../appRedux/ducks/wealthPortfolio";

const WealthSeriesPortfolioPerformanceGraph = ({
  loadSparkline,
  sparklineLoading,
  sparklineData,
  wealthPortfolios,
  tabValue,
}) => {
  const { portfolio } = useParams();
  const [resolution, setResolution] = useState("7d");
  const handleResolutionChange = (_, newResolution) => {
    if (newResolution) {
      setResolution(newResolution);
    }
  };
  const wealthPortfolio = wealthPortfolios[portfolio] || init_portfolio;

  const scalingFactor = 100; // Adjust this factor as needed

  const memoizedSparklineData = useMemo(() => {
    return (
      sparklineData?.map((el, i) => {
        const timestamp = moment(el.created_at).valueOf();
        return {
          x: i,
          date: timestamp,
          y:
            tabValue === 0
              ? (Number(el.twr_performance) * scalingFactor).toFixed(2)
              : (Number(el.total_growth) * scalingFactor).toFixed(2),
        };
      }) || []
    );
  }, [sparklineData, tabValue]);

  const updatedOptions = useMemo(() => {
    if (sparklineData?.length) {
      const isUpwardTrend =
        sparklineData.length > 1 &&
        sparklineData[0].twr_performance <
          sparklineData[sparklineData.length - 1].twr_performance;
      const cutoffDateMs = moment("2024-12-23").valueOf();
      let cutoffIndex = memoizedSparklineData.findIndex(
        (point) => point.date > cutoffDateMs,
      );
      cutoffIndex =
        cutoffIndex === -1 ? memoizedSparklineData.length - 1 : cutoffIndex - 1;
      const annotations = {
        xaxis:
          cutoffIndex > 0
            ? [
                {
                  x: 0,
                  x2: memoizedSparklineData[cutoffIndex].x,
                  fillColor: "#ECECEC",
                  opacity: 0.4,
                  label: {
                    borderColor: "#ECECEC",
                    style: {
                      color: "#333",
                      background: "#ECECEC",
                    },
                    text: "Simulated data",
                  },
                },
              ]
            : [],
      };
      return {
        ...dummyOptions,
        tooltip: {
          ...dummyOptions.tooltip,
          x: {
            formatter: (_, { dataPointIndex }) => {
              const date = sparklineData[dataPointIndex].created_at;
              return moment
                .tz(date, "America/New_York")
                .format("DD MMM 'YY HH:mm");
            },
          },
          y: {
            formatter: (val, { dataPointIndex }) => {
              const currentValue =
                (sparklineData[dataPointIndex].twr_performance * 100).toFixed(
                  2,
                ) + "%";
              const date = sparklineData[dataPointIndex].created_at;
              const dateMoment = moment.tz(date, "America/New_York");
              const formattedDate = dateMoment.format("DD MMM 'YY HH:mm");
              return `<div>${formattedDate}</div><div style='font-size: 14px;'>${currentValue}</div>`;
            },
            title: {
              formatter: () => "", // Remove series name
            },
          },
        },
        colors: [
          tabValue === 1 ? "#1976d2" : isUpwardTrend ? "#5EEECD" : "#F32228",
        ],
        stroke: {
          width: 2,
          curve: "smooth",
        },
        annotations,
      };
    }
    return dummyOptions;
  }, [sparklineData, tabValue, memoizedSparklineData]);

  useEffect(() => {
    if (wealthPortfolio.portfolio_id && resolution) {
      loadSparkline(wealthPortfolio.portfolio_id, resolution);
    }
  }, [resolution, wealthPortfolio.portfolio_id, loadSparkline]);

  return (
    <>
      <div className="ws-portfolio-performance-graph-inform">
        Data before 23 Dec, 2024 is simulated.
      </div>
      <div className="ws-portfolio-performance-graph">
        {sparklineLoading && (
          <div className="loading-overlay">
            <div className="spinner"></div>
          </div>
        )}
        <SparkLines
          series={[
            {
              data: memoizedSparklineData,
            },
          ]}
          options={updatedOptions}
          type={updatedOptions.chart.type}
          height={updatedOptions.chart.height}
          width={updatedOptions.chart.width}
          className="w-full"
        />

        <div className="ws-portfolio-performance-graph__period">
          <ToggleButtonGroup
            value={resolution}
            exclusive
            fullWidth
            onChange={handleResolutionChange}
            aria-label="Graph Period"
            className="ws-portfolio-resolutions"
          >
            <ToggleButton className="ws-portfolio-resolution" value={"1d"}>
              1D
            </ToggleButton>
            <ToggleButton className="ws-portfolio-resolution" value={"7d"}>
              7D
            </ToggleButton>
            <ToggleButton className="ws-portfolio-resolution" value={"1m"}>
              1M
            </ToggleButton>
            {/* <ToggleButton className="ws-portfolio-resolution" value={"6m"}>
              6M
            </ToggleButton> */}
            <ToggleButton className="ws-portfolio-resolution" value={"1y"}>
              1Y
            </ToggleButton>
            <ToggleButton className="ws-portfolio-resolution" value={"ytd"}>
              YTD
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
    </>
  );
};

const stateToProps = (state) => ({
  sparklineData: state.wealthPerformance.sparklineData,
  sparklineLoading: state.wealthPerformance.loading,
  wealthPortfolios: state.wealthPortfolio,
});

const dispatchToProps = (dispatch) => ({
  loadSparkline: (portfolioId, period) =>
    dispatch(portfolioPerformanceLoad(portfolioId, period)),
});

export default connect(
  stateToProps,
  dispatchToProps,
)(WealthSeriesPortfolioPerformanceGraph);
