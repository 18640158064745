import React, { useCallback, useEffect, useState } from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import "./WealthSeriesPortfolioPage.scss";
import WealthSeriesPortfolioInfo from "./components/WealthSeriesPortfolioInfo/WealthSeriesPortfolioInfo";
import WealthSeriesPortfolioPerformanceGraph from "./components/WealthSeriesPortfolioPerformanceGraph/WealthSeriesPortfolioPerformanceGraph";
import WealthSeriesPortfolioTickers from "./components/WealthSeriesPortfolioTickers/WealthSeriesPortfolioTickers";
import WealthSeriesPortfolioKeyAdjustments from "./components/WealthSeriesPortfolioKeyAdjustments/WealthSeriesPortfolioKeyAdjustments";
import WealthSeriesPortfolioNews from "./components/WealthSeriesPortfolioNews/WealthSeriesPortfolioNews";
import WealthSeriesPortfolioTickerInfo from "./components/WealthSeriesPortfolioTickerInfo/WealthSeriesPortfolioTickerInfo";
import { connect } from "react-redux";
import moment from "moment";
import BeautifulSkeleton from "../common/Skeletons/BeautifulSkeleton";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useMedia } from "react-media";
import { portfolioSymbolSetter } from "../../appRedux/ducks/portfolioTicker";
import "moment-timezone";
import { Tab, Tabs } from "@mui/material";
const mediaQuery = {
  isMobile: "screen and (max-width: 991px)",
};

const WealthSeriesPortfolioPage = ({
  portfolioTicker,
  wealthPortfolios,
  loadingPortfolio,
  setTicker,
}) => {
  const { series, portfolio } = useParams();
  const wealthPortfolio = wealthPortfolios[portfolio];
  const lastUpdated = `Last updated: ${moment(wealthPortfolio?.last_updated).tz("America/New_York").format("dddd, MMM D, YYYY h:mm A")} (EST)`;
  const history = useHistory();
  const [tabValue, setTabValue] = useState(0);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const ticker = queryParams.get("ticker");

  const { isMobile } = useMedia({
    queries: mediaQuery,
  });

  const handleChange = useCallback(
    (event, newValue) => setTabValue(newValue),
    [],
  );

  useEffect(() => {
    // Elements we care about
    const backgroundEl = document.querySelector(".site-layout-background");
    const containerEl = document.querySelector(
      ".ws-portfolio-content-container",
    );
    const fixedContentEl = document.querySelector(
      ".ws-portfolio-content-fixed",
    );

    if (!backgroundEl || !containerEl || !fixedContentEl) return;

    const handleWheel = (e) => {
      // Check if the scroll event is happening inside `.ws-portfolio-content-container` or `.ws-portfolio-content-fixed`
      const isInContainer =
        containerEl.contains(e.target) || fixedContentEl.contains(e.target);

      if (isInContainer) {
        // If the user is scrolling inside the container or its children, or inside the fixed content or its children,
        // let them scroll normally. Do nothing.
        return;
      } else {
        // Otherwise, prevent the default scrolling on `.site-layout-background`
        // and manually scroll `.ws-portfolio-content-container` instead.
        e.preventDefault();
        containerEl.scrollTop += e.deltaY;
      }
    };

    backgroundEl.addEventListener("wheel", handleWheel, { passive: false });

    return () => {
      backgroundEl.removeEventListener("wheel", handleWheel, {
        passive: false,
      });
    };
  }, []);
  useEffect(() => {
    if (ticker) {
      setTicker(ticker);
    }
    return () => setTicker("");
  }, []);

  return (
    <div className="ws-portfolio">
      <div className="ws-portfolio-header">
        <div
          onClick={() =>
            history.push(`/wealth-series/${series}${location.search}`)
          }
          className="ws-portfolio-header__back"
        >
          <ArrowBackIosNewIcon />
          <span>Back</span>
        </div>
        {!isMobile && (
          <div className="ws-portfolio-header__timestamp">
            {loadingPortfolio ? (
              <BeautifulSkeleton width={350} height={20} />
            ) : (
              lastUpdated
            )}
          </div>
        )}
      </div>

      <div className="ws-portfolio-content">
        <div className="ws-portfolio-content-fixed">
          <WealthSeriesPortfolioInfo
            tabValue={tabValue}
            setTabValue={setTabValue}
            isMobile={isMobile}
            lastUpdated={lastUpdated}
          />
          <div className="ws-portfolio-content-body ws-scroll-element">
            <div className="ws-portfolio-performance-tabs w-full">
              <Tabs
                value={tabValue}
                onChange={handleChange}
                className="w-full ws-portfolio-ticker-info-tabs"
              >
                <Tab label="Performance" />
                {/* <Tab label="Growth" /> */}
              </Tabs>
            </div>

            <div className="ws-portfolio-info-content">
              {(loadingPortfolio
                ? Array(5).fill({})
                : wealthPortfolio?.performance
              )
                ?.filter(
                  (data) =>
                    data?.period?.toUpperCase() !== "ITD" &&
                    data?.period?.toUpperCase() !== "1H",
                )
                ?.map((data, index) => (
                  <div
                    key={"performance_row_" + index}
                    className="ws-portfolio-info-performance"
                  >
                    {loadingPortfolio ? (
                      <BeautifulSkeleton width={"100%"} height={51} />
                    ) : (
                      <>
                        <div className="ws-portfolio-info-performance-label">
                          {data?.period?.toUpperCase()}
                        </div>
                        <div
                          className={`ws-portfolio-info-performance-value ${Number((data.performance * 100).toFixed(2)) >= 0 ? "positive" : "negative"}`}
                        >
                          {data?.performance > 0 && "+"}
                          {Number((data?.performance * 100).toFixed(2)) === 0
                            ? "0.00"
                            : (data?.performance * 100)?.toFixed(2)}
                          %
                        </div>
                      </>
                    )}
                  </div>
                ))}
            </div>

            <WealthSeriesPortfolioPerformanceGraph tabValue={tabValue} />
            <WealthSeriesPortfolioTickers />
          </div>
        </div>

        <div className="ws-portfolio-content-container">
          <div className="ws-portfolio-content-container__top">
            <div className="ws-portfolio-content-container__top__half">
              <WealthSeriesPortfolioKeyAdjustments />
            </div>

            <div className="ws-portfolio-content-container__top__half ws-portfolio-news-wrapper">
              <WealthSeriesPortfolioNews />
            </div>
          </div>

          <div className="ws-portfolio-content-container__bottom">
            <WealthSeriesPortfolioTickerInfo ticker={portfolioTicker} />
          </div>
        </div>
      </div>

      <div className="ws-portfolio-disclaimer">
        This website provides AI-powered insights for informational purposes
        only. Please use at your own discretion, and consult a qualified
        financial advisor for personalized guidance.
      </div>
    </div>
  );
};

const stateToProps = (state) => ({
  portfolioTicker: state.portfolioTicker.symbol,
  wealthPortfolios: state.wealthPortfolio,
  loadingPortfolio: state.wealthPortfolio.loading,
});

const dispatchToProps = (dispatch) => ({
  setTicker: (sym) => dispatch(portfolioSymbolSetter(sym)),
});

export default connect(
  stateToProps,
  dispatchToProps,
)(WealthSeriesPortfolioPage);
