import React, { useMemo, useState } from "react";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import { Modal } from "@material-ui/core";
import { useAuth } from "../../../contexts/Auth";
import "./AllPricePlans.scss";
import BeautifulSkeleton from "../../common/Skeletons/BeautifulSkeleton";
import ContactForm from "../../EmeraldSignup/ContactForm";
import moment from "moment";
import { connect } from "react-redux";
export const submitStarter = () => {
  window.location.href =
    "https://checkout.tradealgo.com/OTesZx3zJo37D_dpZFHJJgR9S3nfmtdqlU9lUKmI6-c_-n30q4hG3f-1Pcj3KS8YlbiVU431pAXdbtlHGlvw8Jkbdj3AG3SC1ep62Ai2MvlGtc4CMk2Z7g8=";
};

export const submitIntermediate = () => {
  window.location.href =
    "https://checkout.tradealgo.com/OTesZx3zJo37D_JpZFHJJgR9S3nfmtdqlU9lULOS_vA58mqwjZlG0bCUPpzTIy8f2oeDVZDq9l7mYsUBYW3n6ppoVT3ZE3SAxvZ_miO2M_UNhfAvYVD_n2X0vyBI";
};

export const submitAdvanced = () => {
  window.location.href =
    "https://checkout.tradealgo.com/OTesZx3zJo37D_V7CXPIPBg1P1_Mn9ZOuERtH9q97uMq8WyxgNh9xOSyIYbJbAwJlL6JSZft7V2uI-ZEJVjR445NVW_pBDurlN1zyQ-1L_wdjrgCWiaX8Ar2";
};

export const submitPlatinum = () => {
  window.location.href =
    "https://checkout.tradealgo.com/OTesZx3zJo37D_V7CXPIPBg1P1vSn8ZCl11nUNKv7_ktzGqmkp0S8uK0I8j-LTIEmKWHSYCs23LRMowYfA==";
};

const AllPricePlans = ({ userSettings }) => {
  const [product_type, setProduct_type] = useState("");
  // product types: 'PLATINUM STANDARD', OPTIONS MENTORSHIP INTERMEDIATE, OPTIONS MENTORSHIP ADVANCED, OPTIONS MENTORSHIP STARTER 'OPTIONS MENTORSHIP PLATINUM', "EMERAL INCOME ALERTS"
  const [open, setOpen] = useState(false);
  const {
    hasEmerald,
    loading,
    hasOptionsMentorshipStarter,
    hasPlatinumProgram,
    hasOptionsMentorshipIntermediate,
    hasOptionsMentorshipAdvanced,
  } = useAuth();

  const openModal = (product) => {
    setProduct_type(product);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const {
    hasSubmittedomPlatinum,
    hasSubmittedEmerald,
    hasSubmittedomIntermediate,
    hasSubmittedomAdvanced,
  } = useMemo(() => {
    const currentDate = moment();
    const contactDates = {
      hasSubmittedomPlatinum: userSettings?.omPlatinumContact,
      hasSubmittedEmerald: userSettings?.emeraldContact,
      hasSubmittedomIntermediate: userSettings?.omIntermediateContact,
      hasSubmittedomAdvanced: userSettings?.omAdvancedContact,
    };
    return Object.entries(contactDates).reduce((acc, [key, value]) => {
      acc[key] = value
        ? currentDate.isBefore(moment(value).add(1, "week"))
        : false;
      return acc;
    }, {});
  }, [userSettings, loading]);

  const processingStarter =
    userSettings.om_starter_sign_up ||
    !!userSettings?.om_starter_renewed ||
    hasOptionsMentorshipStarter;
  const processingIntermediate =
    !!userSettings?.om_intermediate_sign_up ||
    !!userSettings?.om_intermediate_renewed ||
    hasOptionsMentorshipIntermediate ||
    hasSubmittedomIntermediate;
  const processingAdvanced =
    !!userSettings?.om_advanced_sign_up ||
    !!userSettings?.om_advanced_renewed ||
    hasOptionsMentorshipAdvanced ||
    hasSubmittedomAdvanced;
  const plans = [
    {
      processing: processingStarter,
      onClick: submitStarter.bind(this),
      purchased: hasOptionsMentorshipStarter,
      name: "Starter",
      description: "3 sessions over 3 months",
      price: "$749.50",
    },
    {
      processing: processingIntermediate,
      onClick: () => openModal("OPTIONS MENTORSHIP INTERMEDIATE"),
      purchased: hasOptionsMentorshipIntermediate,
      name: "Intermediate",
      description: "10 sessions over 6 months",
      price: "$2,450",
    },
    {
      processing: processingAdvanced,
      onClick: () => openModal("OPTIONS MENTORSHIP ADVANCED"),
      purchased: hasOptionsMentorshipAdvanced,
      name: "Advanced",
      description: "29 sessions over 12 months",
      price: "$7,000",
    },
  ];
  const premiumPlans = [
    {
      processing: hasSubmittedomPlatinum,
      purchased: hasPlatinumProgram,
      type: "platinum",
      benefits: ["12 sessions over 12 months", "Access to Platinum Program"],
      price: "$9,995",
      isNew: false,
      description: (
        <div className="premium-plan-description">
          Platinum plan will consist of everything that is available in the{" "}
          <span className="highlighted-text">Basic Options Mentorship</span> and{" "}
          <span className="highlighted-text">Platinum Program</span>.
        </div>
      ),
      onClick: () => openModal("OPTIONS MENTORSHIP PLATINUM"),
    },
    {
      processing: hasSubmittedEmerald,
      purchased: hasEmerald,
      type: "emerald",
      benefits: ["32 sessions over 24 months", "Access to Emerald Program"],
      price: "",
      isNew: true,
      description: (
        <div className="premium-plan-description">
          Emerald plan will grant access to{" "}
          <span className="highlighted-text">
            Intermediate and Advanced Options Mentorship
          </span>{" "}
          (8 intermediate sessions, 8 advanced sessions) and full access to{" "}
          <span className="highlighted-text">Emerald Program</span> with 16
          sessions.
        </div>
      ),
      onClick: () => openModal("EMERALD INCOME ALERTS"),
    },
  ];

  const getButtonLabel = (plan) => {
    if (plan.purchased) {
      return "Current Plan";
    } else if (plan.processing) {
      return "Access Requested";
    } else {
      return plan.name === "Starter" ? "Purchase" : "Contact Us";
    }
  };

  return (
    <div className="all-price-plans-page">
      <Modal open={open}>
        <ContactForm product_type={product_type} onClose={handleClose} />
      </Modal>
      <div className="all-price-plans-page__section">
        <div className="all-price-plans-big-label">Choose Your Plan:</div>
        <div className="all-price-plans-small-label">
          Options trading pro-led personalized coaching.
        </div>
        <div className="all-price-plans-plans">
          {plans.map((plan, index) => (
            <div key={index} className="all-price-plans-plan">
              <div className="all-price-plans-plan__name">{plan.name}</div>
              <div className="all-price-plans-plan__description">
                {plan.description}
              </div>
              <div className="all-price-plans-plan__price">
                <span>usd</span>
                {plan.price}
              </div>
              {loading ? (
                <BeautifulSkeleton
                  variant="rectangular"
                  width={"100%"}
                  height={36}
                  style={{ borderRadius: 6 }}
                />
              ) : (
                <Button
                  disabled={plan.purchased || plan.processing}
                  className={`all-price-plans-plan__purchase-button ${plan.purchased ? "purchased" : ""}`}
                  variant="contained"
                  onClick={plan.onClick}
                >
                  {getButtonLabel(plan)}
                </Button>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="all-price-plans-page__section">
        <div className="all-price-plans-big-label">Premium Plan</div>
        <div className="all-price-plans-small-label">
          Personalized 1-on-1 coaching and equipping the users with all the key
          resources and complete access to the program with its own live stream
          and the sought-after Alerts.
        </div>
        <div className="all-price-plans-premium-plans">
          {premiumPlans.map((plan, index) => (
            <div key={index} className="all-price-plans-premium-plan">
              <div
                className={`all-price-plans-premium-plan-content ${plan.type}-content`}
              >
                <div className="all-price-plans-premium-plan__name">
                  {plan.type}
                  {plan.isNew && (
                    <div className="all-price-plans-premium-plan__new-label">
                      New
                    </div>
                  )}
                </div>
                <div className="all-price-plans-premium-plan__benefits">
                  {plan.benefits.map((benefit, index) => (
                    <div
                      key={index}
                      className="all-price-plans-premium-plan__benefit"
                    >
                      <CheckIcon />
                      {benefit}
                    </div>
                  ))}
                </div>
                <div className="all-price-plans-plan__price">
                  {plan.price && (
                    <>
                      <span>usd</span>
                      {plan.price}
                    </>
                  )}
                </div>
                {loading ? (
                  <BeautifulSkeleton
                    variant="rectangular"
                    width={200}
                    height={36}
                    style={{ borderRadius: 6 }}
                  />
                ) : (
                  <Button
                    className={`nowrap all-price-plans-premium-plan__purchase-button ${plan.type}-button ${plan.purchased ? "purchased" : ""}`}
                    variant="contained"
                    onClick={plan.onClick}
                    disabled={plan.purchased || plan.processing}
                  >
                    {getButtonLabel(plan)}
                  </Button>
                )}
              </div>
              <div className="all-price-plans-premium-plan__description">
                {plan.description}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const stateToProps = (state) => ({
  userSettings: state.userSettings.userSettings,
});

export default connect(stateToProps, null)(AllPricePlans);
