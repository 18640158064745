import React, { useMemo, useState } from "react";
import "./LiteMentorship.scss";
import AnimatedMentorsDropdown from "../../common/AnimatedMentorsDropdown/AnimatedMentorsDropdown";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { useAuth } from "../../../contexts/Auth";
import { connect } from "react-redux";
import { updateFieldsLoad } from "../../../appRedux/ducks/updateFields";
import { useSnackbar } from "notistack";

const LiteMentorship = ({ themeMode, setUserFields }) => {
  const { userData, sessionsLeftWealth, mentorshipSessionsUsed } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const mentors = [
    {
      id: 1,
      name: "Dane Glisek",
      calendlyLink:
        "https://calendly.com/analysts-4/wealth-series-lite-mentorship-dane",
    },
    {
      id: 2,
      name: "Mike Andrews",
      calendlyLink:
        "https://calendly.com/analysts-4/wealth-series-lite-mentorship-mike",
    },
  ];

  const sessionsLeft = useMemo(() => {
    return sessionsLeftWealth["lite"];
  }, [sessionsLeftWealth]);

  useCalendlyEventListener({
    onEventScheduled: () => {
      setUserFields({
        training_sessions_used: {
          ...mentorshipSessionsUsed,
          [`wealth_mentorship_lite`]:
            (mentorshipSessionsUsed[`wealth_mentorship_lite`] || 0) + 1,
        },
      });
      enqueueSnackbar(
        "Your session has been booked, please check your email for confirmation!",
        {
          variant: "success",
        },
      );
    },
  });

  const [selectedMentor, setSelectedMentor] = useState({});

  return (
    <div className="lite-mentorship">
      <div className="lite-mentorship-header">
        <div className="lite-mentorship-sessions-available">
          Sessions available:
        </div>
        <div className="lite-mentorship-sessions-number">
          <span>{sessionsLeft}</span> WS Lite Sessions
        </div>
      </div>

      <div className="lite-mentorship-content">
        <div className="lite-mentorship-content-left">
          <div className="lite-mentorship-content-left-body">
            <div className="lite-mentorship-content-banner">
              <div className="lite-mentorship-content-banner-label">
                Mentorship:
              </div>
              <div className="lite-mentorship-content-banner-title">
                Wealth Series Lite
              </div>
            </div>
            {selectedMentor.calendlyLink ? (
              <div
                className={`w-full ${themeMode === "light" ? "ws-light-calendar" : "ws-dark-calendar"}`}
              >
                <InlineWidget
                  prefill={{
                    email: userData?.email || "",
                    name:
                      (userData?.firstName || "") +
                      " " +
                      (userData?.lastName || ""),
                  }}
                  url={selectedMentor.calendlyLink}
                />
              </div>
            ) : (
              <>
                <div className="lite-mentorship-content-description">
                  Discover the power of the Wealth Series Lite portfolio through
                  hands-on learning and actionable insights. Participants will
                  gain an understanding of the portfolio&apos;s unique
                  strategies and learn to leverage the latest market trends to
                  make informed decisions.
                  <br />
                  <br />
                  This program provides practical guidance on options trading,
                  enabling users to explore advanced trading techniques while
                  staying informed with cutting-edge insights from the Wealth
                  Series Lite. Perfect for both beginners and experienced
                  traders, this session equips you with the tools to navigate
                  the dynamic world of options trading with confidence.
                </div>
                {sessionsLeft === 0 && (
                  <div className="lite-mentorship-content-description">
                    <strong>No sessions left</strong>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        {sessionsLeft > 0 && (
          <div className="lite-mentorship-content-right">
            <div className="lite-mentorship-mentors">
              <div className="lite-mentorship-mentors-title">
                Book a new session now:
              </div>
              <AnimatedMentorsDropdown
                mentors={mentors}
                selectedMentor={selectedMentor}
                setSelectedMentor={setSelectedMentor}
                comingSoon={false}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
});

const dispatchToProps = (dispatch) => ({
  setUserFields: (fields) => dispatch(updateFieldsLoad(fields)),
});

export default connect(stateToProps, dispatchToProps)(LiteMentorship);
