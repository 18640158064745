import React, { useMemo } from "react";
import taAvatar from "./taAvatar.png";
import activeCheck from "./activeCheckbox.png";
import inactiveCheck from "./inactiveCheckbox.png";
import SchoolIcon from "@mui/icons-material/School";
import DiamondIcon from "@mui/icons-material/Diamond";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import moment from "moment";
import "./SubscriptionsPage.scss";
import { useAuth } from "../../../contexts/Auth";
import BeautifulSkeleton from "../../common/Skeletons/BeautifulSkeleton";
import { connect } from "react-redux";

const PlanIcon = ({ title, themeMode }) => {
  const wsSvg = (
    <svg
      style={{ marginRight: "13px" }}
      stroke={themeMode === "light" ? "black" : "white"}
      width="50"
      height="50"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_21445_2280)">
        <path
          d="M18.3244 4.91446C17.5327 5.4745 17.1215 6.14337 17.1403 7.12287C17.1979 10.1113 23.4551 10.7711 23.9532 14.3886C24.3208 17.0641 22.5237 18.7952 19.9125 19.1988L20.4904 18.7363C23.2108 13.9578 14.9955 13.6653 14.4587 9.72127C14.1016 7.09566 15.6662 5.27837 18.3244 4.91333V4.91446Z"
          fill="black"
          fillOpacity="1"
        />
        <path
          d="M4.1112 4.80005L7.60924 14.5191L6.1668 19.1989C5.47142 17.5403 4.84417 15.8444 4.22631 14.1552C3.38763 11.8606 2.66641 9.28715 1.70086 7.06968C1.29561 6.13779 0.855129 5.40883 0 4.80005H4.1112Z"
          fill="black"
          fillOpacity="1"
        />
        <path
          d="M12.8046 19.1978L9.28662 9.60572C9.44637 7.98115 10.1934 6.44615 10.7479 4.91455L14.2071 14.7299C14.2776 15.2254 13.2874 18.2716 13.0302 18.9076C12.982 19.0266 12.9867 19.1808 12.8046 19.1978Z"
          fill="black"
          fillOpacity="1"
        />
        <path
          d="M23.0227 8.76786L22.3391 6.87688C21.9797 6.30664 21.5357 5.80669 21.0259 5.36796C21.5932 5.32714 22.3708 5.8509 22.796 5.31694C22.9194 5.16163 22.8207 4.89975 23.0227 4.91335V8.76786Z"
          fill="black"
          fillOpacity="1"
        />
      </g>
      <defs>
        <clipPath id="clip0_21445_2280">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
  const icons = {
    TradeAlgo: <img src={taAvatar} alt="TradeAlgo" />,
    "Platinum Program": <DiamondIcon sx={{ width: "60px", height: "45px" }} />,
    "Options Mentorship": (
      <OndemandVideoIcon
        sx={{
          width: "60px",
          height: "40px",
          position: "relative",
          bottom: "2px",
        }}
      />
    ),
    "Options Academy": <SchoolIcon sx={{ width: "60px", height: "45px" }} />,
    "Wealth Series": wsSvg,
  };
  return icons[title] || "";
};

function SubscriptionRow({ subscription, statusIcon, themeMode }) {
  return (
    <div className="sub-table-row" key={subscription.id}>
      <div className="plan-wrapper">
        <div className="plan-logo">
          <PlanIcon title={subscription?.title?.trim()} themeMode={themeMode} />
        </div>
        <div className="text-wrapp">
          <div className="plan-title"> {subscription.title} </div>
          <div className="plan-subtitle"> {subscription.subtitle} </div>
        </div>
      </div>
      <div className="status-wrapper">
        <div className="inner-status">
          <div className="status-text">
            <img
              className="sub-status-logo"
              src={statusIcon}
              alt={subscription.status}
            />{" "}
            {subscription.status}
          </div>
          <div className="sub-exp-date">{subscription.expires}</div>
        </div>
      </div>
      <div className="sub-price-wrapper">
        <div className="price-text">${subscription.price}</div>
        <div className="price-range">{subscription.period}</div>
      </div>
      <div className="sub-actions-wrapper">
        <div className="actions-text">{subscription.actions}</div>
      </div>
    </div>
  );
}

function SubscriptionsPage({ themeMode }) {
  const { userData, loading } = useAuth();

  const { active, expired } = useMemo(() => {
    const activeSubscriptions =
      userData?.subscriptions?.active?.map((subscription) => ({
        id: subscription.id,
        title: subscription.subscription_type,
        subtitle: subscription.subscription_subtype,
        status: subscription.active ? "Active" : "Expired",
        expires:
          subscription.product_sub_months === 999
            ? "Lifetime"
            : moment(subscription.expires).format("MMM Do YY"),
        price: subscription.sales_price,
        period: subscription.recurring_period,
        actions: subscription.subscription_id ? "Auto Bill" : "Expiring",
      })) || [];
    const expiredSubscriptions =
      userData?.subscriptions?.inactive?.map((subscription) => ({
        id: subscription.id,
        title: subscription.subscription_type,
        subtitle: subscription.subscription_subtype,
        status: "Expired",
        expires:
          subscription.product_sub_months === 999
            ? "Lifetime"
            : moment(subscription.expires).format("MMM Do YY"),
        price: subscription.sales_price,
        period: subscription.recurring_period,
        actions: "Expired",
      })) || [];
    return { active: activeSubscriptions, expired: expiredSubscriptions };
  }, [userData]);

  return (
    <div className="subscriptions-settings-page">
      <div className="subscriptions-settings-page__header">
        My Subscriptions
      </div>
      <SubscriptionList
        title="Active Subscriptions"
        subscriptions={active}
        loading={loading}
        statusIcon={activeCheck}
        themeMode={themeMode}
      />
      <SubscriptionList
        title="Expired Subscriptions"
        subscriptions={expired}
        loading={loading}
        statusIcon={inactiveCheck}
        themeMode={themeMode}
      />
    </div>
  );
}

function SubscriptionList({
  title,
  subscriptions,
  loading,
  statusIcon,
  themeMode,
}) {
  return (
    <div className="subscriptions-settings-item">
      <div
        className={`subscriptions-settings-item__title ${title.toLowerCase().includes("active") ? "active-plan" : ""}`}
      >
        {title}
      </div>
      <div className="subscriptions-settings-item__plan">
        <div className="subscriptions-settings-item__plan-header">
          <div className="subscriptions-settings-item__plan-title">
            Subscription Plan
          </div>
          <div className="subscriptions-settings-item__plan-status">Status</div>
          <div className="subscriptions-settings-item__plan-price">Price</div>
          <div className="subscriptions-settings-item__plan-action">
            Billing Status
          </div>
        </div>
        {loading ? (
          <BeautifulSkeleton
            variant="rectangular"
            width={"100%"}
            height={200}
          />
        ) : (
          <div className="sub-table-rows">
            {subscriptions.map((subscription) => (
              <SubscriptionRow
                key={subscription.id}
                subscription={subscription}
                statusIcon={statusIcon}
                themeMode={themeMode}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
});

export default connect(stateToProps)(SubscriptionsPage);
