import React, { useMemo, useState } from "react";
import { connect } from "react-redux";
import { isValidPhoneNumber } from "libphonenumber-js";
import "./EmeraldSalesContactForm.scss";
import {
  EmeraldBigGreenIcon,
  DarkEmeraldBigGreenIcon,
  EmeraldBigTextIcon,
  IncomeAlertsTextIcon,
} from "../../common/Icon";
import { Button } from "@material-ui/core";
import { useAuth } from "../../../contexts/Auth";
import PhoneNumberInput from "./PhoneNumberInput";
import { contactFormLoad } from "../../../appRedux/ducks/contactForm";
import { addUserSettingsLoad } from "../../../appRedux/ducks/userSettings";
import moment from "moment";
import WealthSeriesSignupLogo from "../../../assets/images/wealth-series-signup/product-logo.png";

const renderPhoneField = (label, value, onChange) => (
  <PhoneNumberInput label={label} value={value} onChange={onChange} />
);

const ContactForm = ({
  themeMode,
  onClose,
  submitContactForm,
  userSettings,
  setUserSettings,
  product_type,
  // product types: 'PLATINUM STANDARD', OPTIONS MENTORSHIP INTERMEDIATE, OPTIONS MENTORSHIP ADVANCED, OPTIONS MENTORSHIP STARTER 'OPTIONS MENTORSHIP PLATINUM', "EMERAL INCOME ALERTS"
}) => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { userData, user } = useAuth();
  const email = userData?.email || user?.email;
  const handlePhoneChange = (value) => {
    setPhone(value);
  };

  const color = useMemo(() => {
    return themeMode === "dark" ? "#fff" : "#000";
  }, [themeMode]);

  const validateForm = ({ name, phone }) => {
    let errors = {};
    let isValid = true;

    if (!name) {
      errors.name = "Name is required.";
      isValid = false;
    }

    if (!phone) {
      errors.phone = "Phone number is required.";
      isValid = false;
    } else if (!isValidPhoneNumber(phone)) {
      errors.phone = "Please enter a valid phone number.";
      isValid = false;
    }

    return { valid: isValid, errors };
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { valid, errors } = validateForm({ email, name, phone });
    if (valid) {
      submitContactForm({ email, name, phone, product_type });
      let settings = { ...userSettings };
      const currentDate = moment().format("YYYY-MM-DD");
      switch (product_type) {
        case "PLATINUM STANDARD":
          settings.platinumStandardContact = currentDate;
          break;
        case "OPTIONS MENTORSHIP PLATINUM":
          settings.omPlatinumContact = currentDate;
          break;
        case "EMERALD INCOME ALERTS":
          settings.emeraldContact = currentDate;
          break;
        case "OPTIONS MENTORSHIP INTERMEDIATE":
          settings.omIntermediateContact = currentDate;
          break;
        case "OPTIONS MENTORSHIP ADVANCED":
          settings.omAdvancedContact = currentDate;
          break;
        case "OPTIONS MENTORSHIP STARTER":
          settings.omStarterContact = currentDate;
          break;
        case "WEALTH SERIES":
          settings.wsContact = currentDate;
          break;
        case "WEALTH SERIES LITE":
          settings.wsLiteContact = currentDate;
          break;
        default:
          console.error(`Unknown product type: ${product_type}`);
      }
      setUserSettings(settings, false, true);
      setIsSubmitted(true);
      setErrors({});
    } else {
      setErrors(errors);
    }
  };

  const renderLogos = () => {
    switch (product_type) {
      case "PLATINUM STANDARD":
        return (
          <div className="emerald-sales-contact-form__header gap-0">
            <div className="platinum-program-sign-up-title in-modal">
              The Platinum Program
            </div>
          </div>
        );
      case "OPTIONS MENTORSHIP PLATINUM":
        return (
          <div className="emerald-sales-contact-form__header gap-0">
            <div className="platinum-program-sign-up-title in-modal">
              Options Mentorship Platinum
            </div>
          </div>
        );
      case "EMERALD INCOME ALERTS":
        return (
          <div className="emerald-sales-contact-form__header">
            <div className="emerald-sales-contact-form__logo">
              {themeMode === "light" ? (
                <EmeraldBigGreenIcon />
              ) : (
                <DarkEmeraldBigGreenIcon />
              )}
            </div>
            <div className="emerald-sales-contact-form__text_logos">
              <EmeraldBigTextIcon fill={color} />
              <IncomeAlertsTextIcon fill={color} />
            </div>
          </div>
        );
      case "WEALTH SERIES":
        return (
          <img
            src={WealthSeriesSignupLogo}
            alt="Wealth Series Logo"
            className="w-full"
          />
        );
      case "WEALTH SERIES LITE":
        return (
          <img
            src={WealthSeriesSignupLogo}
            alt="Wealth Series Lite Logo"
            className="w-full"
          />
        );
      default:
        return (
          <div className="emerald-sales-contact-form__header">
            {" "}
            {product_type}{" "}
          </div>
        );
    }
  };

  return (
    <div
      className={`emerald-sales-contact-form ${product_type.includes("WEALTH SERIES") ? "dark-contact-form" : ""}`}
    >
      {renderLogos()}
      {isSubmitted ? (
        <div className="emerald-sales-contact-form__info">
          <div className="emerald-sales-contact-form__title">
            Thank you for expressing interest
          </div>
          <div className="emerald-sales-contact-form__subtitle">
            You are one step closer towards elite trading success. Our sales
            executive will get in touch with you shortly.{" "}
          </div>
        </div>
      ) : (
        <div className="emerald-sales-contact-form__info">
          <div className="emerald-sales-contact-form__title">
            Sales Contact Form
          </div>
          <div className="emerald-sales-contact-form__subtitle">
            Thank you for expressing interest. Fill in your contact details
            below and our sales executive will get in touch with you shortly.
          </div>
        </div>
      )}

      {isSubmitted ? (
        <div className="emerald-sales-contact-form__content">
          <Button
            variant="contained"
            className={`emerald-sales-submit-button ${product_type.includes("PLATINUM") ? "platinum-background" : product_type.includes("WEALTH SERIES") ? "ws-background" : ""}`}
            onClick={onClose}
          >
            Close
          </Button>
        </div>
      ) : (
        <div className="emerald-sales-contact-form__content">
          <form onSubmit={handleSubmit}>
            <div className="emerald-sales-contact-form__input">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                placeholder="Enter Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {errors.name && <div className="error">{errors.name}</div>}
            </div>
            <div className="emerald-sales-contact-form__input ">
              <label htmlFor="email">Email Address</label>
              <input
                className="disabled-cursor"
                type="email"
                id="email"
                placeholder="Enter Email"
                value={email}
                disabled
              />
            </div>
            <div className="emerald-sales-contact-form__input phone-input-emerald">
              {renderPhoneField("Phone Number", phone, handlePhoneChange)}
              {errors.phone && <div className="error">{errors.phone}</div>}
            </div>
            <Button
              type="submit"
              variant="contained"
              className={`emerald-sales-submit-button ${product_type.includes("PLATINUM") ? "platinum-background" : product_type.includes("WEALTH SERIES") ? "ws-background" : ""}`}
            >
              Submit
            </Button>
            <Button
              variant="text"
              className="emerald-sales-cancel-button"
              onClick={onClose}
            >
              Cancel
            </Button>
          </form>
        </div>
      )}
    </div>
  );
};

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
  userSettings: state.userSettings.userSettings,
});

const dispatchToProps = (dispatch) => ({
  submitContactForm: (formData) => dispatch(contactFormLoad(formData)),
  setUserSettings: (settings, firstRender, fetch) =>
    dispatch(addUserSettingsLoad(settings, firstRender, fetch)),
});

export default connect(stateToProps, dispatchToProps)(ContactForm);
