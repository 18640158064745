/* eslint-disable no-case-declarations */

import { all, put, takeEvery, fork, call } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";
import { showSnackbar } from "./snackbar";
// Action Types
//init
export const USER_PORTFOLIOS_LOAD = "USER_PORTFOLIOS/LOAD";
export const USER_PORTFOLIOS_LOAD_SUCCESS = "USER_PORTFOLIOS/LOAD/SUCCESS";
export const USER_PORTFOLIOS_LOAD_FAILURE = "USER_PORTFOLIOS/LOAD/FAILURE";
//post
export const USER_PORTFOLIOS_CREATE = "USER_PORTFOLIOS/CREATE";
export const USER_PORTFOLIOS_CREATE_SUCCESS = "USER_PORTFOLIOS/CREATE/SUCCESS";
export const USER_PORTFOLIOS_CREATE_FAILURE = "USER_PORTFOLIOS/CREATE/FAILURE";
//update
export const USER_PORTFOLIOS_UPDATE = "USER_PORTFOLIOS/UPDATE";
export const USER_PORTFOLIOS_UPDATE_SUCCESS = "USER_PORTFOLIOS/UPDATE/SUCCESS";
export const USER_PORTFOLIOS_UPDATE_FAILURE = "USER_PORTFOLIOS/UPDATE/FAILURE";
//performance
export const USER_PORTFOLIOS_PERFORMANCE_LOAD =
  "USER_PORTFOLIOS/PERFORMANCE/LOAD";
export const USER_PORTFOLIOS_PERFORMANCE_LOAD_SUCCESS =
  "USER_PORTFOLIOS/PERFORMANCE/LOAD/SUCCESS";
export const USER_PORTFOLIOS_PERFORMANCE_LOAD_FAILURE =
  "USER_PORTFOLIOS/PERFORMANCE/LOAD/FAILURE";

// Action Creators
export const userPortfoliosLoad = () => ({
  type: USER_PORTFOLIOS_LOAD,
});
export const userPortfoliosLoadSuccess = (data) => ({
  type: USER_PORTFOLIOS_LOAD_SUCCESS,
  data,
});
export const userPortfoliosLoadFailure = (error) => ({
  type: USER_PORTFOLIOS_LOAD_FAILURE,
  error,
});
//post
export const userPortfoliosCreate = (portfolio) => ({
  type: USER_PORTFOLIOS_CREATE,
  payload: portfolio,
});
export const userPortfoliosCreateSuccess = (data) => ({
  type: USER_PORTFOLIOS_CREATE_SUCCESS,
  data,
});
export const userPortfoliosCreateFailure = (error) => ({
  type: USER_PORTFOLIOS_CREATE_FAILURE,
  error,
});
//update
export const userPortfoliosUpdate = (portfolio, id) => ({
  // New action creator for update
  type: USER_PORTFOLIOS_UPDATE,
  payload: { portfolio, id },
});
export const userPortfoliosUpdateSuccess = (data) => ({
  // New action creator for update success
  type: USER_PORTFOLIOS_UPDATE_SUCCESS,
  data,
});
export const userPortfoliosUpdateFailure = (error) => ({
  // New action creator for update failure
  type: USER_PORTFOLIOS_UPDATE_FAILURE,
  error,
});
//performance
export const userPortfoliosPerformanceLoad = (id, period = "1d") => ({
  type: USER_PORTFOLIOS_PERFORMANCE_LOAD,
  payload: { id, period },
});
export const userPortfoliosPerformanceLoadSuccess = (idPeriodKey, data) => ({
  type: USER_PORTFOLIOS_PERFORMANCE_LOAD_SUCCESS,
  payload: { idPeriodKey, data },
});
export const userPortfoliosPerformanceLoadFailure = (error) => ({
  type: USER_PORTFOLIOS_PERFORMANCE_LOAD_FAILURE,
  error,
});

// Sagas
function* fetchUserPortfolios() {
  try {
    const response = yield axios.get(
      `${apiURL}/wealth-series-lite/portfolios`,
      {
        withCredentials: true,
      },
    );
    yield put(userPortfoliosLoadSuccess(response?.data || []));
  } catch (error) {
    yield put(userPortfoliosLoadFailure(error));
  }
}

function calculateTotalWeightage(allocations) {
  const total = allocations.reduce(
    (acc, curr) => acc + curr.ticker_weightage,
    0,
  );
  return Number(total.toFixed(2));
}

function hasZeroWeightage(allocations) {
  return allocations.some((allocation) => allocation.ticker_weightage === 0);
}

function* createUserPortfolio(action) {
  const totalWeightage = calculateTotalWeightage(action.payload.allocations);
  if (totalWeightage !== 1) {
    yield put(
      showSnackbar(`Total Percentage must amount to 100.`, {
        variant: "error",
        from: "ws-lite",
      }),
    );
    return;
  }
  if (hasZeroWeightage(action.payload.allocations)) {
    yield put(
      showSnackbar(`No ticker's quantity can be 0.`, {
        variant: "error",
        from: "ws-lite",
      }),
    );
    return;
  }
  try {
    const response = yield call(
      axios.post,
      `${apiURL}/wealth-series-lite/portfolios`,
      action.payload,
      {
        withCredentials: true,
      },
    );
    yield put(userPortfoliosCreateSuccess(response.data));
    // After successfully creating a user portfolio, fetch the updated list of portfolios
    if (response.status === 200) {
      yield put({ type: USER_PORTFOLIOS_LOAD });
      yield put(
        showSnackbar(`Successfully created Portfolio!`, {
          variant: "success",
          from: "ws-lite",
        }),
      );
    }
  } catch (error) {
    yield put(userPortfoliosCreateFailure(error));
  }
}

function* updateUserPortfolio(action) {
  // New saga for update
  const { portfolio, id } = action.payload;
  const totalWeightage = calculateTotalWeightage(portfolio.allocations);
  if (totalWeightage !== 1) {
    yield put(
      showSnackbar(`Total Percentage must amount to 100.`, {
        variant: "error",
        from: "ws-lite",
      }),
    );
    return;
  }
  if (hasZeroWeightage(portfolio.allocations)) {
    yield put(
      showSnackbar(`No ticker's quantity can be 0.`, {
        variant: "error",
        from: "ws-lite",
      }),
    );
    return;
  }
  try {
    const response = yield call(
      axios.put,
      `${apiURL}/wealth-series-lite/portfolios/${id}`,
      portfolio,
      {
        withCredentials: true,
      },
    );
    yield put(userPortfoliosUpdateSuccess(response.data));
    // After successfully updating a user portfolio, fetch the updated list of portfolios
    if (response.status === 200) {
      yield put({ type: USER_PORTFOLIOS_LOAD });
      yield put(
        showSnackbar(`Successfully Updated Portfolio!`, {
          variant: "success",
          from: "ws-lite",
        }),
      );
    } else {
      yield put(
        showSnackbar(`Error encountered updating portfolio.`, {
          variant: "error",
          from: "ws-lite",
        }),
      );
    }
  } catch (error) {
    yield put(userPortfoliosUpdateFailure(error));
  }
}

function* fetchPortfolioPerformance(action) {
  const { id, period } = action.payload;
  const idPeriodKey = `${id}_${period}`;
  try {
    const response = yield call(
      axios.get,
      `${apiURL}/wealth-series-lite/portfolios/${id}/performance`,
      {
        params: { period },
        withCredentials: true,
      },
    );
    yield put(
      userPortfoliosPerformanceLoadSuccess(
        idPeriodKey,
        response?.data?.performance?.records || [],
      ),
    );
  } catch (error) {
    yield put(userPortfoliosPerformanceLoadFailure(error));
  }
}

function* listenUserPortfoliosLoad() {
  yield takeEvery(USER_PORTFOLIOS_LOAD, fetchUserPortfolios);
}

function* listenUserPortfoliosCreate() {
  yield takeEvery(USER_PORTFOLIOS_CREATE, createUserPortfolio);
}

function* listenUserPortfoliosUpdate() {
  // New listener for update
  yield takeEvery(USER_PORTFOLIOS_UPDATE, updateUserPortfolio);
}

function* listenUserPortfoliosPerformanceLoad() {
  yield takeEvery(USER_PORTFOLIOS_PERFORMANCE_LOAD, fetchPortfolioPerformance);
}

// Root Saga
export function* saga() {
  yield all([
    fork(listenUserPortfoliosLoad),
    fork(listenUserPortfoliosCreate),
    fork(listenUserPortfoliosUpdate),
    fork(listenUserPortfoliosPerformanceLoad),
  ]);
}

const INIT_STATE = {
  userPortfolios: [],
  createPortfolioResult: null, // Separate state var for the result of the POST
  updatePortfolioResult: null, // New state var for the result of the PUT
  loading: true,
  createLoading: false, // Separate loading var for create operation
  updateLoading: false, // New loading var for update operation
  error: null,
  performanceLoading: false,
  performanceError: null,
  performanceData: {}, // Changed to an object to map performance data by idPeriodKey
};
// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case USER_PORTFOLIOS_LOAD:
      return {
        ...state,
        loading: true,
      };
    case USER_PORTFOLIOS_LOAD_SUCCESS:
      return {
        ...state,
        userPortfolios: action.data,
        loading: false,
      };
    case USER_PORTFOLIOS_LOAD_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case USER_PORTFOLIOS_CREATE:
      return {
        ...state,
        createLoading: true,
      };
    case USER_PORTFOLIOS_CREATE_SUCCESS:
      return {
        ...state,
        createPortfolioResult: action.data, // Update the separate state var for the result
        createLoading: false,
      };
    case USER_PORTFOLIOS_CREATE_FAILURE:
      return {
        ...state,
        error: action.error,
        createLoading: false,
      };
    case USER_PORTFOLIOS_UPDATE: // New case for update
      return {
        ...state,
        updateLoading: true,
      };
    case USER_PORTFOLIOS_UPDATE_SUCCESS: // New case for update success
      return {
        ...state,
        updatePortfolioResult: action.data,
        updateLoading: false,
      };
    case USER_PORTFOLIOS_UPDATE_FAILURE: // New case for update failure
      return {
        ...state,
        error: action.error,
        updateLoading: false,
      };
    case USER_PORTFOLIOS_PERFORMANCE_LOAD:
      return {
        ...state,
        performanceLoading: true,
        performanceError: null,
      };
    case USER_PORTFOLIOS_PERFORMANCE_LOAD_SUCCESS:
      const { idPeriodKey, data } = action.payload;
      return {
        ...state,
        performanceData: {
          ...state.performanceData,
          [idPeriodKey]: data,
        },
        performanceLoading: false,
        performanceError: null,
      };
    case USER_PORTFOLIOS_PERFORMANCE_LOAD_FAILURE:
      return {
        ...state,
        performanceError: action.error,
        performanceLoading: false,
      };
    default:
      return state;
  }
};

export default reducer;
