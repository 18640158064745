import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { Tab, Tabs } from "@mui/material";
import moment from "moment";
import { dxFeedSocketListen } from "../../appRedux/ducks/dxFeedSocket";
import "moment-timezone";
import wsLiteLight from "../../assets/images/wealth-series/ws-lite-light.png";
import wsLiteDark from "../../assets/images/wealth-series/ws-lite-dark.png";

import "../WealthSeriesOverview/WealthSeriesOverview.scss";
import "./LiteContainer.scss";
import LiteStockPicks from "./LiteStockPicks/LiteStockPicks";
import WSTelegramCTA from "../WealthSeriesOverview/components/WSTelegramCTA/WSTelegramCTA";
import LiteLeaderboard from "./LiteLeaderboard/LiteLeaderboard";
import LiteLivestream from "./LiteLivestream/LiteLivestream";
import LiteMentorship from "./LiteMentorship/LiteMentorship";
import BeautifulSkeleton from "../common/Skeletons/BeautifulSkeleton";
import { useAuth } from "../../contexts/Auth";

const LiteContainer = ({ themeMode, userPortfolios, dateLoading }) => {
  const hardHistory = useHistory();
  const { tab } = useParams();
  const [value, setValue] = useState(tab);
  const { isDemo } = useAuth();
  const [isBlurred, setIsBlurred] = useState(false);
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setIsBlurred(searchParams.get("blurred") === "true" || isDemo);
  }, [location, isDemo]);
  const handleChange = useCallback((event, newValue) => setValue(newValue), []);
  const badPages = ["livestream", "mentorship"];
  const last_updated = useMemo(() => {
    if (userPortfolios.length > 0) {
      const date = userPortfolios[0].last_updated;
      return moment(date)
        .tz("America/New_York")
        .format("dddd, MMM D, YYYY [at] h:mm A [(EST)]");
    }
    return moment()
      .tz("America/New_York")
      .format("dddd, MMM D, YYYY [at] h:mm A [(EST)]");
  }, [userPortfolios]);

  // scroll to top smoothly on component rendering
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [tab]);

  useEffect(() => {
    setValue(tab);
  }, [tab]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    hardHistory.push({
      pathname: `/wealth-series-lite/${value}`,
      search: searchParams.toString(),
    });
  }, [value, hardHistory, location.search]);

  const goBookMentorship = () => {
    setValue("mentorship");
  };
  return (
    <div className="ws-lite-stock-picks">
      <div className="flex-between">
        <img
          className="ws-lite-logo"
          src={themeMode === "light" ? wsLiteLight : wsLiteDark}
        />
        <WSTelegramCTA
          className="ws-lite-telegram-cta"
          link="https://t.me/wealthserieslite"
        />
      </div>
      {!badPages.includes(value) && (
        <div className="last-updated-text last-updated-text-mobile">
          {dateLoading ? (
            <BeautifulSkeleton width={350} height={22} />
          ) : (
            `Last updated: ${last_updated}`
          )}
        </div>
      )}
      <div className="ws-lite-overview__tabs">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          className="fit-content ws-portfolio-ticker-info-tabs ws-lite-tabs"
        >
          <Tab label="AI Stock Picks" value={"ai-top-picks"} />
          <Tab label="Portfolio Leaderboard" value={"leaderboard"} />
          <Tab label="Livestream" value={"livestream"} />
          <Tab label="Mentorship" value={"mentorship"} />
        </Tabs>
        {!badPages.includes(value) && (
          <div className="last-updated-text">
            {dateLoading ? (
              <BeautifulSkeleton width={350} height={23} />
            ) : (
              `Last updated: ${last_updated}`
            )}
          </div>
        )}
      </div>

      <WSTelegramCTA
        className="ws-lite-telegram-cta ws-lite-telegram-cta-mobile"
        link="https://t.me/wealthserieslite"
      />

      <div className="ws-lite-overview__content">
        {value === "ai-top-picks" && <LiteStockPicks isBlurred={isBlurred} />}
        {value === "leaderboard" && <LiteLeaderboard isBlurred={isBlurred} />}
        {value === "livestream" && (
          <LiteLivestream goBookMentorship={goBookMentorship} />
        )}
        {value === "mentorship" && <LiteMentorship />}
      </div>
    </div>
  );
};

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
  stockRecs: state.liteStockRecs.stockRecommendations,
  loading: state.liteStockRecs.loading,
  dateLoading: state.userPortfolio.loading,
  userPortfolios: state.userPortfolio.userPortfolios,
});

const dispatchToProps = (dispatch) => ({
  listen: (symbols, options, disconnect, risk) =>
    dispatch(dxFeedSocketListen(symbols, options, disconnect, risk)),
});

export default connect(stateToProps, dispatchToProps)(LiteContainer);
