import { all, put, takeEvery, fork } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";
const INIT_STATE = {
  sparklineData: [
    {
      twr_performance: 0,
      created_at: "",
    },
  ],
  loading: true,
  error: null,
};

// Action Types
export const PORTFOLIO_PERFORMANCE_LOAD = "PORTFOLIO/PERFORMANCE/LOAD";
export const PORTFOLIO_PERFORMANCE_LOAD_SUCCESS =
  "PORTFOLIO/PERFORMANCE/LOAD/SUCCESS";
export const PORTFOLIO_PERFORMANCE_LOAD_FAILURE =
  "PORTFOLIO/PERFORMANCE/LOAD/FAILURE";

// Action Creators
export const portfolioPerformanceLoad = (portfolioId, period) => ({
  type: PORTFOLIO_PERFORMANCE_LOAD,
  portfolioId,
  period,
});

export const portfolioPerformanceLoadSuccess = (data) => ({
  type: PORTFOLIO_PERFORMANCE_LOAD_SUCCESS,
  data,
});

export const portfolioPerformanceLoadFailure = (error) => ({
  type: PORTFOLIO_PERFORMANCE_LOAD_FAILURE,
  error,
});

// Sagas
function* fetchPortfolioPerformance(action) {
  const { portfolioId, period } = action;

  try {
    // Set loading state
    yield put(
      portfolioPerformanceLoadSuccess({
        sparklineData: null,
        loading: true,
      }),
    );

    const response = yield axios.get(
      `${apiURL}/wealth-series/portfolios/${portfolioId}/performance?period=${period}`,
      { withCredentials: true },
    );

    yield put(
      portfolioPerformanceLoadSuccess({
        sparklineData: response.data.performance.records,
        loading: false,
      }),
    );
  } catch (error) {
    yield put(portfolioPerformanceLoadFailure(error));
  }
}

function* listenPortfolioPerformanceLoad() {
  yield takeEvery(PORTFOLIO_PERFORMANCE_LOAD, fetchPortfolioPerformance);
}

// Root Saga
export function* saga() {
  yield all([fork(listenPortfolioPerformanceLoad)]);
}

// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case PORTFOLIO_PERFORMANCE_LOAD_SUCCESS:
      return {
        ...state,
        sparklineData: action.data.sparklineData,
        loading: action.data.loading,
        error: null,
      };
    case PORTFOLIO_PERFORMANCE_LOAD_FAILURE:
      return {
        ...state,
        sparklineData: INIT_STATE.sparklineData,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default reducer;
