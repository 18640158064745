import React, { useState } from "react";
import { connect } from "react-redux";
import VerifiedIcon from "@mui/icons-material/Verified";
import UserAvatar from "../../../assets/images/dane.png";
import "./LiteLivestream.scss";
// import PreviousVideos from "../../BasicsTraining/PreviousVideos";
import { defaultVideoState } from "../../../helpers/defaultVideoState";
import SocialCareLightImg from "../../../assets/images/social-care.gif";
import SocialCareDarkImg from "../../../assets/images/social-care-dark.gif";

const LiteLiveStream = ({ themeMode, goBookMentorship }) => {
  // const [currentVideo, setCurrentVideo] = useState(defaultVideoState);
  // const [tab, setTab] = useState("1");
  const [currentVideo] = useState(defaultVideoState);
  const [tab] = useState("1");

  return (
    <div className="lite-live-stream">
      {currentVideo.live ? (
        <div className="lite-live-stream__header">
          <div className="lite-live-stream__left">
            <iframe
              className="lite-live-stream__video"
              src={`https://vimeo.com/event/4845593/embed`}
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              title={"LiveStream"}
              frameBorder="0"
            ></iframe>
          </div>

          <div className="lite-live-stream__right">
            <iframe
              src={`https://vimeo.com/event/4845593/chat`}
              width="100%"
              height="100%"
              frameBorder="0"
              title="livestream-chat"
            />
          </div>
        </div>
      ) : (
        <div>
          <div
            dangerouslySetInnerHTML={{
              __html: currentVideo.url,
            }}
          />
          <div style={{ position: "relative", top: "5px", left: "29px" }}>
            <div className="row">
              <span
                className="col-12"
                style={{ color: "#A2A3A5", whiteSpace: "nowrap" }}
              >
                {currentVideo.duration} | {currentVideo.date} |{" "}
                {tab === "1" ? "Group Training" : ""}
              </span>
            </div>
            <div className="row">
              <span className="col-12" style={{ paddingTop: "10px" }}>
                {currentVideo.description}
              </span>
            </div>
          </div>
          <br />
          <div
            style={{
              width: "99%",
              border: "1px solid #F1F1F1",
              marginLeft: "24px",
            }}
          ></div>
          <br />
        </div>
      )}
      <div className="lite-live-stream__body">
        <div className="lite-live-stream__left">
          <div className="lite-live-stream__content">
            <div className="lite-live-stream-author">
              <img
                src={UserAvatar}
                alt="Dane Glisek"
                className="lite-live-stream-author-avatar"
              />
              <div className="lite-live-stream-author-info">
                <div className="lite-live-stream-author-name">
                  Dane Glisek <VerifiedIcon />
                </div>
                <div className="lite-live-stream-author-title">
                  TradeAlgo Professional Analyst
                </div>
              </div>
            </div>

            <div className="lite-live-stream__content-title">
              Wealth Series Lite Livestream
            </div>
            <div className="lite-live-stream__content-scheduleDate">
              Every Thursday 4:30 PM EST
            </div>
            <div className="lite-live-stream__content-description">
              Dane will conduct a daily walkthrough of our Wealth Series Lite
              portfolios and reveal the latest insights on the market.
            </div>
          </div>
        </div>

        <div className="lite-live-stream__right lite-live-stream-cta-container">
          <div className="lite-live-stream-cta" onClick={goBookMentorship}>
            <img
              src={
                themeMode === "light" ? SocialCareLightImg : SocialCareDarkImg
              }
              alt="social-care"
              className="lite-live-stream-cta-img"
            />
            <div className="lite-live-stream-cta-texts">
              <div className="lite-live-stream-cta-text">Book your 1-on-1</div>
              <div className="lite-live-stream-cta-text">
                Mentorship Session
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <PreviousVideos
        tab={tab}
        setTab={setTab}
        basics={false}
        titleFilter={null}
        customPageSize={9}
        currentVideo={currentVideo}
        setCurrentVideo={setCurrentVideo}
        videoKey={`wealth-series-atlas`}
      /> */}
    </div>
  );
};

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
});

export default connect(stateToProps, null)(LiteLiveStream);
