import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import "./LiteLeaderboard.scss";
import "./LiteLeaderboardRowMobile.scss";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import LiteLeaderboardRow from "./LiteLeaderboardRow";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { leaderboardLoad } from "../../../appRedux/ducks/leaderboard";
import { connect } from "react-redux";
import { userPortfoliosLoad } from "../../../appRedux/ducks/userPortfolio";
import { useAuth } from "../../../contexts/Auth";
import { findLongestName } from "../../homePage/DashboardHeader";
import PortfolioTooltip from "../LiteStockPicks/PortfolioTooltip";

const LiteLeaderboard = ({
  loadLeaderboard,
  leaderboardData,
  leaderboardLoading,
  userPortfoliosLoad,
  userRankData,
  isBlurred,
}) => {
  const history = useHistory();
  const location = useLocation();
  // Parse query parameters to get the pid and resolution
  const queryParams = new URLSearchParams(location.search);
  const initialPid = queryParams.get("pid");
  const initialResolution = queryParams.get("resolution") || "1d";

  const [resolution, setResolution] = useState(initialResolution);
  const [expandedRow, setExpandedRow] = useState(initialPid || null);

  const toggleRowView = (rowId) => {
    setExpandedRow((prev) => (prev === rowId ? null : rowId));
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (expandedRow) {
      queryParams.set("pid", expandedRow);
    } else {
      queryParams.delete("pid");
    }
    queryParams.set("resolution", resolution);
    history.replace({ search: queryParams.toString() });
  }, [expandedRow, resolution, location.search]);

  const handleResolutionChange = (event, newResolution) => {
    if (newResolution) {
      setResolution(newResolution);
    }
  };
  const { user, userData } = useAuth();
  const userName = useMemo(
    () => findLongestName(userData, user),
    [userData, user],
  );
  useEffect(() => {
    userPortfoliosLoad();
  }, []);

  useEffect(() => {
    loadLeaderboard(resolution);
  }, [resolution]);
  return (
    <div className="lite-leaderboard">
      <div className="lite-leaderboard__header">
        <div className="lite-leaderboard__header-title">
          <EmojiEventsIcon className="lite-leaderboard__header-icon" />
          Portfolio Leaderboard
          <PortfolioTooltip />
        </div>

        <div className="lite-leaderboard__header-timestamp">
          <ToggleButtonGroup
            exclusive
            fullWidth
            aria-label="Leaderboard Period"
            className="ws-portfolio-resolutions"
            value={resolution}
            onChange={handleResolutionChange}
          >
            <ToggleButton className="ws-portfolio-resolution" value={"1d"}>
              1D
            </ToggleButton>
            <ToggleButton className="ws-portfolio-resolution" value={"7d"}>
              7D
            </ToggleButton>
            <ToggleButton className="ws-portfolio-resolution" value={"1m"}>
              1M
            </ToggleButton>
            <ToggleButton className="ws-portfolio-resolution" value={"1y"}>
              1Y
            </ToggleButton>
            <ToggleButton className="ws-portfolio-resolution" value={"itd"}>
              ITD
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>

      <div className="lite-leaderboard__content">
        <div className="lite-leaderboard__content-header">
          <div className="lite-leaderboard__collapse_button"></div>
          <div className="lite-leaderboard__pr">Portfolio Rank</div>
          <div className="lite-leaderboard__user">User</div>
          <div className="lite-leaderboard__focus">Focus</div>
          <div className="lite-leaderboard__stocks">Stocks Included</div>
          <div className="lite-leaderboard__performance">
            {resolution.toUpperCase()} Performance
          </div>
          <div className="lite-leaderboard__action">Action</div>
        </div>

        <div className="lite-leaderboard__content-body">
          {!leaderboardLoading || !!leaderboardData.length ? (
            <>
              {leaderboardData.slice(0, 10).map((portfolio, index) => (
                <LiteLeaderboardRow
                  isBlurred={isBlurred}
                  portfolio={portfolio}
                  key={index}
                  rank={index + 1}
                  resolution={resolution}
                  isExpanded={expandedRow === portfolio?.portfolio_id}
                  toggleRowView={() => toggleRowView(portfolio?.portfolio_id)}
                  highlight={
                    portfolio?.portfolio_id === userRankData?.portfolio_id
                  }
                />
              ))}

              <div className="lite-leaderboard__content__splitter">
                <div className="lite-leaderboard__split_line" />
                <MoreHorizIcon />
                <div className="lite-leaderboard__split_line" />
              </div>

              {!leaderboardData.some(
                (portfolio) =>
                  portfolio?.portfolio_id === userRankData?.portfolio_id,
              ) &&
                !!userRankData?.portfolio_id && (
                  <LiteLeaderboardRow
                    isBlurred={isBlurred}
                    id={userRankData?.portfolio_id}
                    portfolio={userRankData}
                    rank={userRankData?.rank}
                    resolution={resolution}
                    name={userName}
                    isExpanded={expandedRow === userRankData?.portfolio_id}
                    toggleRowView={() =>
                      toggleRowView(userRankData?.portfolio_id)
                    }
                  />
                )}
            </>
          ) : (
            <>
              {Array.from({ length: 10 })
                .fill(null)
                .map((portfolio, index) => (
                  <LiteLeaderboardRow
                    dataLoading={leaderboardLoading}
                    key={index}
                  />
                ))}

              <div className="lite-leaderboard__content__splitter">
                <div className="lite-leaderboard__split_line" />
                <MoreHorizIcon />
                <div className="lite-leaderboard__split_line" />
              </div>

              <LiteLeaderboardRow dataLoading={leaderboardLoading} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const stateToProps = (state) => ({
  leaderboardData: state.leaderboard.portfolios,
  leaderboardLoading: state.leaderboard.loading,
  userRankData: state.leaderboard.userPortfolio,
});

const dispatchToProps = (dispatch) => ({
  loadLeaderboard: (resolution) => dispatch(leaderboardLoad(resolution)),
  userPortfoliosLoad: () => dispatch(userPortfoliosLoad()),
});

export default connect(stateToProps, dispatchToProps)(LiteLeaderboard);
