// Action Types
export const THEME_SET = "THEME/SET";

// Action Creators
export const themeSetter = (themeMode) => {
  return {
    type: THEME_SET,
    themeMode,
  };
};

const INIT_STATE = {
  themeMode: localStorage.getItem("theme") || "light",
};
// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case THEME_SET:
      return {
        ...state,
        themeMode: action.themeMode,
      };
    default:
      return state;
  }
};

export default reducer;
