import * as React from "react";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom";

function ActionButtons({ step, setStep, userSettings, setUserSettings }) {
  const history = useHistory();
  const [buttonText, setButtonText] = React.useState("Begin walkthrough");
  const [cancelText, setCancelText] = React.useState("Skip walkthrough");

  React.useEffect(() => {
    if (step === 1) {
      setButtonText("Begin walkthrough");
      setCancelText("Skip walkthrough");
    } else {
      setButtonText("Continue");
      setCancelText("Back");
    }
  }, [step]);

  const handleBack = () => {
    if (step === 1) {
      setUserSettings({
        ...userSettings,
        wsLiteCompletedOnboarding: true,
      });
      history.push("/wealth-series-lite/ai-top-picks");
    } else {
      setStep(1);
    }
  };

  const handleNext = () => {
    setStep(step + 1);
  };
  return (
    <div className="actions-container">
      {!!(step !== 2 && step !== 3 && step !== 4 && step !== 5) && (
        <div className={`buttons-wrapper ${step >= 6 ? "" : ""}`}>
          <Button
            onClick={handleBack}
            className="button-outline ws-lite-onboarding-secondary-button"
            tabIndex="0"
            variant="outlined"
          >
            {cancelText}
          </Button>
          <Button
            onClick={handleNext}
            className="button-primary ws-lite-onboarding-primary-button"
            tabIndex="0"
            variant="contained"
          >
            {buttonText}
          </Button>
        </div>
      )}
      {step > 1 ? (
        <div className="subscription-notice" tabIndex="0">
          These stock picks are for demonstration purposes only.
          <br />
          Latest stock picks are available after this walkthrough.
        </div>
      ) : (
        <div className="subscription-notice" tabIndex="0">
          Upon registration, you have been subscribed to bi-weekly email alerts
          on stock pick movements of Wealth Series Lite.
        </div>
      )}
    </div>
  );
}
export default ActionButtons;
