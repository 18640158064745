import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import AnimateOnChange from "react-animate-on-change";
import "./addedRowsAnimations.scss";
import { isInternalWS } from "../../../../appRedux/actions/helpers";

function CssPop({ comparator, ticker, currentPrices, dollar = true }) {
  const [price, setPrice] = useState(comparator);
  const previousPriceRef = useRef(comparator);
  const [animationClassName, setAnimationClassName] = useState("neutral");

  // Debounce function to limit updates
  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  };

  useEffect(() => {
    const updatePrice = () => {
      const price = currentPrices.get(ticker) || comparator;
      const parsedPrice = typeof price === "string" ? parseFloat(price) : price;

      if (parsedPrice !== previousPriceRef.current) {
        setPrice(parsedPrice);
        setAnimationClassName(
          `new-item-options${parsedPrice > previousPriceRef.current ? "-up" : "-down"}`,
        );
        previousPriceRef.current = parsedPrice;
      }
    };

    const debouncedUpdatePrice = debounce(updatePrice, 100); // Adjust delay as needed
    debouncedUpdatePrice();
  }, [currentPrices, ticker, comparator]);

  return (
    <AnimateOnChange
      baseClassName="neutral"
      animationClassName={animationClassName}
      animate={price !== comparator}
    >
      {`${dollar ? "$" : ""}${Number(price).toFixed(2)}`}
    </AnimateOnChange>
  );
}

const stateToProps = (state) => ({
  currentPrices: isInternalWS
    ? state.EquitiesSocket.currentPrices
    : state.dxFeedSocket.socketData,
});

export default connect(stateToProps, null)(CssPop);
