import React, { useMemo } from "react";
import moment from "moment-timezone";
import { useAuth } from "../../contexts/Auth";
import TaLogo from "../../../src/assets/images/TradeAlgo.png";
import "./index.scss";
import { Button } from "@mui/material";

export const findLongestName = (user1, user2 = {}) => {
  let possibleNames = [];
  const addUserNames = (user) => {
    if (!!user?.firstName && !!user?.lastName) {
      possibleNames.push(`${user?.firstName} ${user?.lastName}`);
    }
    if (user?.display_name) {
      possibleNames.push(user?.display_name);
    }
    if (user?.displayName) {
      possibleNames.push(user?.displayName);
    }
  };

  addUserNames(user1);
  addUserNames(user2);

  if (possibleNames.length === 0) return "";
  // Find the longest name
  let longestName = possibleNames.reduce((longest, current) => {
    return current.length > longest.length ? current : longest;
  });

  return longestName;
};

const DashboardHeader = ({
  darkflowActiveHeaderTab,
  changeDarkflowActiveHeaderTab,
}) => {
  const { userData, user } = useAuth();
  const formattedDate = moment().format("MMMM Do, YYYY");
  let name = useMemo(() => {
    return findLongestName(userData, user);
  }, [userData, user]);

  return (
    <div className="dashboard-top-banner">
      <div className="welcome-container">
        <div className="welcome-titles">
          <div className="logo-dashboard">
            <img alt="logo-w-text" src={TaLogo} className="logo-img" />
            <span className="dashboard-title">Dashboard</span>
          </div>

          <div className="date-time">
            {/* <div className="time">{formattedTime}</div> */}
            <div className="date">Date: {formattedDate}</div>
          </div>
        </div>

        <div className="welcome-title">
          <span className="hello-text">Hello,</span>{" "}
          <span className="name">{name ? name : "Guest"}</span>
        </div>

        <div className="info">
          Here are the top stocks handpicked by our AI from over 10,000 tickers
          in the Stock Exchange
        </div>

        <hr className="separator" />

        <div className="message-header2">
          <p className="info">
            Choose from the available styles of trading that will suit your
            needs
          </p>
        </div>
        <div className="trading-buttons">
          <Button
            className={`trading-button ${darkflowActiveHeaderTab === "1" ? "active" : ""}`}
            onClick={() => changeDarkflowActiveHeaderTab("1")}
          >
            Intraday Trading
          </Button>
          <Button
            className={`trading-button ${darkflowActiveHeaderTab === "2" ? "active" : ""}`}
            onClick={() => changeDarkflowActiveHeaderTab("2")}
          >
            Swing Trading
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DashboardHeader;
