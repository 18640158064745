import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import moment from "moment";
import { dummyOptions } from "../../TradingView/helpers";
import SparkLines from "../../common/SparkLines";
import { userPortfoliosPerformanceLoad } from "../../../appRedux/ducks/userPortfolio";
import SampleGraphImage from "../../../assets/images/wealth-series-lite/stockPicks/sampleGraph.png";

const LitePerformanceGraph = ({
  sparklineData,
  loading,
  portfolioId,
  userPortfoliosPerformanceLoad,
  hideResolution = false,
  resolution = "1d",
  setResolution = () => {},
  height = 600,
  fetch = true,
}) => {
  useEffect(() => {
    if (
      !sparklineData[`${portfolioId}_${resolution}`] &&
      portfolioId &&
      fetch
    ) {
      userPortfoliosPerformanceLoad(portfolioId, resolution);
    }
  }, [
    portfolioId,
    resolution,
    userPortfoliosPerformanceLoad,
    sparklineData,
    fetch,
  ]);

  const handleResolutionChange = (event, newResolution) => {
    if (newResolution) {
      setResolution(newResolution);
    }
  };

  const memoizedSparklineData = useMemo(() => {
    const idPeriodKey = `${portfolioId}_${resolution}`;
    return (
      sparklineData?.[idPeriodKey]?.map((el, i) => {
        return {
          x: i, // Keep x as index for step values
          y: Number(el.twr_performance * 100),
          date: moment(el.created_at).valueOf(), // Store date separately for tooltip
        };
      }) || []
    );
  }, [sparklineData, portfolioId, resolution]);
  const updatedOptions = useMemo(() => {
    if (memoizedSparklineData?.length) {
      const isUpwardTrend =
        memoizedSparklineData.length > 1 &&
        memoizedSparklineData[0].y <
          memoizedSparklineData[memoizedSparklineData.length - 1].y;
      return {
        ...dummyOptions,
        chart: {
          ...dummyOptions.chart,
          height,
        },
        tooltip: {
          ...dummyOptions.tooltip,
          x: {
            formatter: () => "", // Not displaying x-axis values directly
          },
          y: {
            formatter: (_, { dataPointIndex }) => {
              const currentValue =
                Number(memoizedSparklineData[dataPointIndex].y).toFixed(2) +
                "%";
              const date = memoizedSparklineData[dataPointIndex].date;
              const dateMoment = moment.tz(date, "America/New_York");
              const formattedDate = dateMoment.format("DD MMM 'YY");
              return `<div>${formattedDate}</div><div style='font-size: 14px;'>${currentValue}</div>`;
            },
            title: {
              formatter: () => "", // Series name removed
            },
          },
        },
        colors: [isUpwardTrend ? "#5EEECD" : "#F32228"],
      };
    }
    return dummyOptions;
  }, [memoizedSparklineData]);

  return (
    <div className="ws-portfolio-performance-graph">
      {loading ? (
        <div className="ws-portfolio-performance-graph-loader">
          <div className="spinner"></div>
        </div>
      ) : (
        <>
          {memoizedSparklineData && memoizedSparklineData.length > 1 ? (
            <SparkLines
              series={[
                {
                  data: memoizedSparklineData,
                },
              ]}
              options={updatedOptions}
              type={updatedOptions.chart.type}
              height={updatedOptions.chart.height}
              width={updatedOptions.chart.width}
              className="w-full"
            />
          ) : (
            <div className="lite-stock-sample-graph-container">
              <img
                src={SampleGraphImage}
                alt="graph"
                className="lite-stock-graph"
              />
              <div className="lite-stock-sample-overlay" />
              <div className="lite-stock-sample-empty-label">
                Your performance chart will be available after your portfolio is
                saved.
              </div>
            </div>
          )}
        </>
      )}
      {!hideResolution && (
        <div className="ws-portfolio-performance-graph__period">
          <ToggleButtonGroup
            value={resolution}
            exclusive
            fullWidth
            onChange={handleResolutionChange}
            aria-label="Graph Period"
            className="ws-portfolio-resolutions"
          >
            <ToggleButton className="ws-portfolio-resolution" value={"1d"}>
              1D
            </ToggleButton>
            <ToggleButton className="ws-portfolio-resolution" value={"7d"}>
              7D
            </ToggleButton>
            <ToggleButton className="ws-portfolio-resolution" value={"1m"}>
              1M
            </ToggleButton>
            <ToggleButton className="ws-portfolio-resolution" value={"1y"}>
              1Y
            </ToggleButton>
            <ToggleButton className="ws-portfolio-resolution" value={"ytd"}>
              YTD
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      )}
    </div>
  );
};

const stateToProps = (state) => ({
  sparklineData: state.userPortfolio.performanceData,
  loading: state.userPortfolio.performanceLoading,
});

const dispatchToProps = (dispatch) => ({
  userPortfoliosPerformanceLoad: (id, period) =>
    dispatch(userPortfoliosPerformanceLoad(id, period)),
});

export default connect(stateToProps, dispatchToProps)(LitePerformanceGraph);
