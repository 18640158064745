import React, { useEffect } from "react";
import { Button } from "@mui/material";
import "./DeletedAccount.scss";
import { useAuth } from "../../contexts/Auth";
import { useHistory } from "react-router-dom";

const DeletedAccountPage = () => {
  const { signOut } = useAuth();
  const history = useHistory();

  useEffect(() => {
    signOut();
  }, []);

  return (
    <div className="deleted-account-page">
      <div className="header"></div>
      <div className="content">
        <div className="message">
          <h1 className="oops">Oops!</h1>
          <p className="description">
            You do not have an account because it has been deleted or
            deactivated. If you believe this was an error, please contact us at
            <a className="contact-link" href="mailto:support@tradealgo.com">
              support@tradealgo.com
            </a>
            .
          </p>
          <Button
            onClick={() => {
              history.push("/signin");
            }}
            variant="outlined"
            className="back-button"
          >
            Go back
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DeletedAccountPage;
