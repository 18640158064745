import React from "react";
import FallbackTickerIcon from "../../common/FallbackTickerIcon/FallbackTickerIcon";
import { Button, IconButton, Modal } from "@mui/material";
import "./LiteStockPerformanceNews.scss";
import CloseIcon from "@mui/icons-material/Close";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import BeautifulSkeleton from "../../common/Skeletons/BeautifulSkeleton";

const LiteStockPerformanceNews = ({
  activeTicker,
  ticker,
  news,
  isTickerInPortfolio,
  togglePortfolio,
  isBlurred,
  loading = false,
}) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
    // add classname .scroll-freeze to body
    document.body.classList.add("scroll-freeze");
  };
  const handleClose = () => {
    setOpen(false);
    // remove classname .scroll-freeze from body
    document.body.classList.remove("scroll-freeze");
  };

  const renderMarkdown = (markdownText) => {
    const htmlText = markdownText
      ?.replace(/(\*\*|__)(.*?)\1/g, "<strong>$2</strong>") // Bold
      ?.replace(/(\*|_)(.*?)\1/g, "<em>$2</em>") // Italic
      ?.replace(
        /\[([^\]]+)\]\(([^)]+)\)/g,
        '<a href="$2" target="_blank" rel="noopener noreferrer">$1</a>',
      ) // Links
      ?.replace(/\n/g, "<br />"); // Newline
    return { __html: htmlText };
  };

  return (
    <div className="lite-stock-performance-news">
      {loading ? (
        <>
          <div className="lite-stock-performance-news__header">
            <div className={`lite-stock-performance-news__ticker_info`}>
              <BeautifulSkeleton width={32} height={32} variant="circular" />
              <BeautifulSkeleton width={80} height={32} />
            </div>

            <div className="lite-stock-performance-news__actions">
              <BeautifulSkeleton width={200} height={32} />
              <BeautifulSkeleton width={32} height={32} />
            </div>
          </div>

          <div className="lite-stock-performance-news__content">
            {Array.from({ length: 8 }, () => {
              const randomWidth =
                Math.floor(Math.random() * (100 - 40 + 1)) + 30; // Random width between 50% and 100%
              const randomMarginTop = Math.floor(Math.random() * 10); // Random margin-top between 0 and 9
              return (
                <BeautifulSkeleton
                  key={Math.random()} // Using Math.random() to ensure a unique key
                  width={`${randomWidth}%`}
                  height={20}
                  style={{ marginTop: `${randomMarginTop}px` }}
                />
              );
            })}
          </div>
        </>
      ) : (
        <>
          <div className="lite-stock-performance-news__header">
            <div
              className={`lite-stock-performance-news__ticker_info ${isBlurred ? "blurred" : ""}`}
            >
              <FallbackTickerIcon
                src={`https://assets.tradealgo.com/stock-logos/${ticker}.png`}
                className="lite-stock-performance-news__ticker_icon"
                ticker={ticker}
              />
              <div className="lite-stock-performance-news__ticker_caption">
                Why {ticker}
              </div>
            </div>

            <div className="lite-stock-performance-news__actions">
              <Button
                className={`lite-stock-performance-news__add_button ${isBlurred ? "blurred" : ""}`}
                onClick={() => togglePortfolio(activeTicker)}
              >
                {isTickerInPortfolio(ticker)
                  ? `Remove ${ticker} from portfolio`
                  : `Add ${ticker} to portfolio`}
              </Button>
              <IconButton
                className="lite-stock-pick__ticker_add"
                size="small"
                onClick={handleOpen}
              >
                <OpenInFullIcon />
              </IconButton>
            </div>
          </div>

          <div className="lite-stock-performance-news__content">
            <div
              className={`lite-stock-performance-news__description ${isBlurred ? "blurred" : ""}`}
              dangerouslySetInnerHTML={renderMarkdown(news)}
            />
          </div>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div className="lite-stock-performance-news-dialog">
              <div className="lite-stock-performance-news__header lite-stock-performance-news__header--modal">
                <div className="lite-stock-performance-news__ticker_info">
                  <FallbackTickerIcon
                    src={`https://assets.tradealgo.com/stock-logos/${ticker}.png`}
                    className="lite-stock-performance-news__ticker_icon"
                    ticker={ticker}
                  />
                  <div className="lite-stock-performance-news__ticker_caption">
                    Why {ticker}
                  </div>
                </div>

                <div className="lite-stock-performance-news__actions">
                  <Button
                    className="lite-stock-performance-news__add_button"
                    onClick={() => togglePortfolio(activeTicker)}
                  >
                    {isTickerInPortfolio(ticker)
                      ? `Remove ${ticker} from portfolio`
                      : `Add ${ticker} to portfolio`}
                  </Button>
                  <IconButton
                    className="lite-stock-pick__ticker_add"
                    size="small"
                    onClick={handleClose}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>

              <div className="lite-stock-performance-news__content lite-stock-performance-news__content--modal">
                <div
                  className={`lite-stock-performance-news__description ${isBlurred ? "blurred" : ""}`}
                  dangerouslySetInnerHTML={renderMarkdown(news)}
                />
              </div>
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

export default LiteStockPerformanceNews;
