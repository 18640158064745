import { Button, Tooltip } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import LinkIcon from "@mui/icons-material/Link";
import FallbackTickerIcon from "../../common/FallbackTickerIcon/FallbackTickerIcon";
import { leaderboardLookup } from "../LiteStockPicks/LiteStockPicks";
import { connect } from "react-redux";
import { leaderboardDetailLoad } from "../../../appRedux/ducks/leaderboard";
import { useMedia } from "react-media";
import { userPortfoliosPerformanceLoad } from "../../../appRedux/ducks/userPortfolio";
import LitePerformanceGraph from "../LiteStockPicks/LitePerformanceGraph";
import { dxFeedSocketListen } from "../../../appRedux/ducks/dxFeedSocket";
import CssPop from "../../common/Tables/animations/CssPop";
import BeautifulSkeleton from "../../common/Skeletons/BeautifulSkeleton";
import { useSnackbar } from "notistack";
import { truncateText } from "../../company/Sentiment";
import { useEffect, useMemo } from "react";

const mediaQuery = {
  isMobile: "screen and (max-width: 768px)",
};

const LiteLeaderboardRow = ({
  portfolio,
  rank,
  loadLeaderboardDetail,
  portfolioDetail,
  portfolioDetailLoading,
  resolution,
  id = null,
  name = null,
  isExpanded,
  toggleRowView,
  listen,
  dataLoading = false,
  highlight = false,
  isBlurred,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const { isMobile } = useMedia({
    queries: mediaQuery,
  });

  const allTickers = useMemo(() => {
    return portfolio?.allocations?.map((allocation) => allocation.ticker);
  }, [portfolio?.allocations]);

  useEffect(() => {
    if (isExpanded) {
      listen(allTickers);
      return () => {
        listen([]);
      };
    }
  }, [allTickers, listen, isExpanded]);

  const growth = Number(portfolio?.twr_performance * 100)?.toFixed(2);
  useEffect(() => {
    if (!portfolioDetail[id || portfolio?.portfolio_id] && isExpanded) {
      loadLeaderboardDetail(id || portfolio?.portfolio_id);
    }
  }, [id, portfolio?.portfolio_id, portfolioDetail, isExpanded]);

  const holdings = useMemo(
    () => (portfolioDetail[id || portfolio?.portfolio_id] || {})?.holdings,
    [portfolioDetail, id, portfolio?.portfolio_id],
  );

  const copyLink = () => {
    if (portfolio?.portfolio_id) {
      navigator.clipboard.writeText(
        `${window.location.origin}/wealth-series-lite/leaderboard?pid=${portfolio?.portfolio_id}&resolution=${resolution}`,
      );

      enqueueSnackbar("Link was copied", {
        className: "custom-snackbar ws-lite-snackbar success",
        variant: "success",
      });
    }
  };

  return (
    <>
      {isMobile ? (
        dataLoading ? (
          <div className="lite-leaderboard__mobile_row">
            <div className="lite-leaderboard__mobile_row_basic">
              <div className="lite-leaderboard_mobile_row_basic_main_content">
                <div className="lite-leaderboard__mobile_row_header">
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    Portfolio Rank
                    <BeautifulSkeleton width={24} height={24} />
                  </span>
                  <BeautifulSkeleton width={24} height={24} />
                </div>
                <div className="lite-leaderboard__mobile_row_data">
                  <div className="lite-leaderboard__mobile_row_data_row">
                    <div className="lite-leaderboard__mobile_row_data_row-label">
                      User
                    </div>
                    <div className="lite-leaderboard__mobile_row_data_row-value">
                      <BeautifulSkeleton width={80} height={20} />
                    </div>
                  </div>
                  <div className="lite-leaderboard__mobile_row_data_row">
                    <div className="lite-leaderboard__mobile_row_data_row-label">
                      Focus
                    </div>
                    <div className="lite-leaderboard__mobile_row_data_row-value">
                      <BeautifulSkeleton width={100} height={20} />
                    </div>
                  </div>
                  <div className="lite-leaderboard__mobile_row_data_row">
                    <div className="lite-leaderboard__mobile_row_data_row-label">
                      Stocks Included
                    </div>
                    <div className="lite-leaderboard__mobile_row_data_row-value stocks_included">
                      {Array.from({ length: 5 })
                        .fill(null)
                        .map((allocation, index) => (
                          <span
                            key={index}
                            className="lite-leaderboard__stock_icon_wrapper"
                          >
                            <BeautifulSkeleton
                              variant="circular"
                              className="lite-leaderboard__stock_icon"
                              height={24}
                            />
                          </span>
                        ))}
                    </div>
                  </div>
                  <div className="lite-leaderboard__mobile_row_data_row">
                    <div className="lite-leaderboard__mobile_row_data_row-label">
                      {resolution?.toUpperCase()} Performance
                    </div>
                    <div
                      className={`lite-leaderboard__mobile_row_data_row-value`}
                    >
                      <BeautifulSkeleton width={50} height={20} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="lite-leaderboard__mobile_row_basic_action">
                <Button>
                  <ExpandMoreIcon />
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className="lite-leaderboard__mobile_row">
            <div className="lite-leaderboard__mobile_row_basic">
              <div className="lite-leaderboard_mobile_row_basic_main_content">
                <div className="lite-leaderboard__mobile_row_header">
                  <span>Portfolio Rank #{rank || "N/A"}</span>
                  <Tooltip
                    title="Copy link to portfolio"
                    placement="left"
                    arrow
                    enterDelay={500}
                    leaveDelay={200}
                  >
                    <Button onClick={copyLink}>
                      <LinkIcon />
                    </Button>
                  </Tooltip>
                </div>
                <div className="lite-leaderboard__mobile_row_data">
                  <div className="lite-leaderboard__mobile_row_data_row">
                    <div className="lite-leaderboard__mobile_row_data_row-label">
                      User
                    </div>
                    <div className="lite-leaderboard__mobile_row_data_row-value">
                      {name || portfolio?.user_name || "Private User"}
                    </div>
                  </div>
                  <div className="lite-leaderboard__mobile_row_data_row">
                    <div className="lite-leaderboard__mobile_row_data_row-label">
                      Focus
                    </div>
                    <div className="lite-leaderboard__mobile_row_data_row-value">
                      {leaderboardLookup[portfolio?.portfolio_focus]?.title ||
                        "N/A"}
                    </div>
                  </div>
                  <div className="lite-leaderboard__mobile_row_data_row">
                    <div className="lite-leaderboard__mobile_row_data_row-label">
                      Stocks Included
                    </div>
                    <div className="lite-leaderboard__mobile_row_data_row-value stocks_included">
                      {portfolio?.allocations?.map((allocation, index) => (
                        <span
                          key={index}
                          className="lite-leaderboard__stock_icon_wrapper"
                        >
                          <FallbackTickerIcon
                            src={`https://assets.tradealgo.com/stock-logos/${allocation.ticker}.png`}
                            className="lite-leaderboard__stock_icon h-full"
                            ticker={allocation.ticker}
                          />
                        </span>
                      )) || "N/A"}
                    </div>
                  </div>
                  <div className="lite-leaderboard__mobile_row_data_row">
                    <div className="lite-leaderboard__mobile_row_data_row-label">
                      {resolution?.toUpperCase()} Performance
                    </div>
                    <div
                      className={`lite-leaderboard__mobile_row_data_row-value ${growth > 0 ? "color-green" : "color-red"}`}
                    >
                      {growth > 0 ? "+" : ""}
                      {growth}%
                    </div>
                  </div>
                </div>
              </div>
              <div className="lite-leaderboard__mobile_row_basic_action">
                <Button onClick={toggleRowView}>
                  {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Button>
              </div>
            </div>

            <div
              className={`lite-leaderboard__expanded-content ${isExpanded ? "expanded" : ""}`}
            >
              <div className="lite-leaderboard__expanded-body">
                <div className="lite-leaderboard__expanded_chart mobile">
                  <LitePerformanceGraph
                    fetch={isExpanded}
                    hideResolution
                    height={200}
                    portfolioId={portfolio?.portfolio_id}
                    resolution={resolution}
                  />
                </div>

                <div className="lite-leaderboard__expanded_details">
                  <div className="lite-leaderboard__expanded_details_header">
                    <div className="lite-leaderboard__expanded_details_ph">
                      Portfolio Holdings
                    </div>
                    <div className="lite-leaderboard__expanded_details_current_price">
                      Current Price
                    </div>
                    <div className="lite-leaderboard__expanded_details_tracked_price">
                      Open Price
                    </div>
                    <div className="lite-leaderboard__expanded_details_overall_performance">
                      Overall Performance
                    </div>
                    <div className="lite-leaderboard__expanded_details_period_performance">
                      Performance
                    </div>
                    <div className="lite-leaderboard__expanded_details_allocation">
                      Allocation
                    </div>
                  </div>

                  {portfolioDetailLoading[portfolio?.portfolio_id] ? (
                    Array.from({ length: 3 })
                      .fill(null)
                      .map((_, index) => (
                        <div
                          className="lite-leaderboard__expanded_details_row color-black"
                          key={index}
                        >
                          <div className="lite-leaderboard__expanded_details_ph">
                            <BeautifulSkeleton
                              variant="circular"
                              width={24}
                              height={24}
                            />
                            <span className="font-14 font-bold">
                              <BeautifulSkeleton width={100} height={20} />
                            </span>
                          </div>
                          <div
                            className="lite-leaderboard__expanded_details_current_price"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <BeautifulSkeleton width={80} height={20} />
                          </div>
                          <div
                            className="lite-leaderboard__expanded_details_tracked_price"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <BeautifulSkeleton width={80} height={20} />
                          </div>
                          <div
                            className={`lite-leaderboard__expanded_details_overall_performance`}
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <BeautifulSkeleton width={80} height={20} />
                          </div>
                          <div
                            className={`lite-leaderboard__expanded_details_period_performance`}
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <BeautifulSkeleton width={80} height={20} />
                          </div>
                          <div
                            className="lite-leaderboard__expanded_details_allocation"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <BeautifulSkeleton width={50} height={20} />
                          </div>
                        </div>
                      ))
                  ) : holdings?.length > 0 ? (
                    holdings.map((data, index) => (
                      <div
                        className="lite-leaderboard__expanded_details_row color-black"
                        key={index}
                      >
                        <div className="lite-leaderboard__expanded_details_ph">
                          <FallbackTickerIcon
                            src={`https://assets.tradealgo.com/stock-logos/${data.ticker}.png`}
                            className="lite-leaderboard__expanded_stock_icon"
                            ticker={data.ticker}
                          />
                          <span className="font-14 font-bold">
                            {data?.companyName || "N/A"}
                          </span>
                        </div>
                        <div className="lite-leaderboard__expanded_details_current_price">
                          <CssPop
                            comparator={data.currentPrice}
                            ticker={data.ticker}
                          />
                        </div>
                        <div className="lite-leaderboard__expanded_details_tracked_price">
                          ${data.trackedPrice || "N/A"}
                        </div>
                        <div
                          className={`lite-leaderboard__expanded_details_overall_performance ${data.overallPerformance > 0 ? "color-green" : "color-red"}`}
                        >
                          {data.overallPerformance > 0 ? "+" : ""}
                          {data.overallPerformance?.toFixed(2) || "N/A"}%
                        </div>
                        <div
                          className={`lite-leaderboard__expanded_details_period_performance ${data.periodPerformance > 0 ? "color-green" : "color-red"}`}
                        >
                          {data.periodPerformance > 0 ? "+" : ""}
                          {data.performance?.toFixed(2) || "N/A"}%
                        </div>
                        <div className="lite-leaderboard__expanded_details_allocation">
                          {(data.ticker_weightage * 100)?.toFixed(2) || "N/A"}%
                        </div>
                      </div>
                    ))
                  ) : (
                    <div>No holdings data available.</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )
      ) : dataLoading ? (
        <div className="lite-leaderboard__row">
          <div className="lite-leaderboard__content-row">
            <div className="lite-leaderboard__collapse_button">
              <ExpandMoreIcon />
            </div>
            <div className="lite-leaderboard__pr">
              <BeautifulSkeleton width={50} height={20} />
            </div>
            <div className="lite-leaderboard__user">
              <BeautifulSkeleton width={150} height={20} />
            </div>
            <div className="lite-leaderboard__focus">
              <BeautifulSkeleton width={150} height={20} />
            </div>
            <div
              className={`lite-leaderboard__stocks ${isBlurred ? "blurred" : ""}`}
            >
              {Array.from({ length: 5 })
                .fill(null)
                .map((allocation, index) => (
                  <span
                    key={index}
                    className="lite-leaderboard__stock_icon_wrapper"
                  >
                    <BeautifulSkeleton
                      variant="circular"
                      className="lite-leaderboard__stock_icon"
                      height={24}
                    />
                  </span>
                ))}
            </div>
            <div
              className={`lite-leaderboard__performance`}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <BeautifulSkeleton width={100} height={20} />
            </div>
            <div className="lite-leaderboard__action">
              <BeautifulSkeleton width={40} height={24} />
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`lite-leaderboard__row ${highlight ? "highlighted" : ""}`}
        >
          <div
            className="lite-leaderboard__content-row"
            onClick={toggleRowView}
          >
            <div className="lite-leaderboard__collapse_button">
              {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </div>
            <div className="lite-leaderboard__pr font-14 color-black">
              #{rank}
            </div>
            <div className="lite-leaderboard__user font-14">
              {name || portfolio?.user_name || "Private User"}
            </div>
            <div className="lite-leaderboard__focus font-14 color-black">
              {leaderboardLookup[portfolio?.portfolio_focus]?.title}
            </div>
            <div
              className={`lite-leaderboard__stocks ${isBlurred ? "blurred" : ""} font-14`}
            >
              {portfolio?.allocations?.map((allocation, index) => (
                <span
                  key={index}
                  className="lite-leaderboard__stock_icon_wrapper"
                >
                  <FallbackTickerIcon
                    src={`https://assets.tradealgo.com/stock-logos/${allocation.ticker}.png`}
                    className="lite-leaderboard__stock_icon h-full"
                    ticker={allocation.ticker}
                  />
                </span>
              ))}
            </div>
            <div
              className={`lite-leaderboard__performance font-14 ${growth > 0 ? "color-green" : "color-red"}`}
            >
              {growth > 0 ? "+" : ""}
              {growth}%
            </div>
            <div
              className="lite-leaderboard__action font-14"
              onClick={(e) => e.stopPropagation()}
            >
              <Tooltip title="Copy link to portfolio" placement="left">
                <Button onClick={copyLink}>
                  <LinkIcon />
                </Button>
              </Tooltip>
            </div>
          </div>

          <div
            className={`lite-leaderboard__expanded-content ${isExpanded ? "expanded" : ""}`}
          >
            <div className="lite-leaderboard__expanded-body">
              <div className="lite-leaderboard__expanded_chart">
                <LitePerformanceGraph
                  fetch={isExpanded}
                  hideResolution
                  height={300}
                  portfolioId={portfolio?.portfolio_id}
                  resolution={resolution}
                />
              </div>
              <div className="lite-leaderboard__expanded_details">
                <div className="lite-leaderboard__expanded_details_header">
                  <div className="lite-leaderboard__expanded_details_ph">
                    Portfolio Holdings
                  </div>
                  <div className="lite-leaderboard__expanded_details_current_price">
                    Current Price
                  </div>
                  <div className="lite-leaderboard__expanded_details_tracked_price">
                    Tracked Price
                  </div>
                  <div className="lite-leaderboard__expanded_details_overall_performance">
                    Overall Performance
                  </div>
                  <div className="lite-leaderboard__expanded_details_period_performance">
                    {resolution?.toUpperCase()} Performance
                  </div>
                  <div className="lite-leaderboard__expanded_details_allocation">
                    Allocation
                  </div>
                </div>

                {(portfolioDetailLoading[portfolio?.portfolio_id]
                  ? Array.from({ length: 3 }).fill(null)
                  : holdings
                )?.map((data, index) =>
                  portfolioDetailLoading[portfolio?.portfolio_id] ? (
                    <div
                      className="lite-leaderboard__expanded_details_row color-black"
                      key={index}
                    >
                      <div className="lite-leaderboard__expanded_details_ph">
                        <BeautifulSkeleton
                          variant="circular"
                          width={24}
                          height={24}
                        />
                        <span className="font-14 font-bold">
                          <BeautifulSkeleton width={50} height={20} />
                        </span>
                        <span className="font-14 lite-leaderboard-ticker-name">
                          <BeautifulSkeleton width={100} height={20} />
                        </span>
                      </div>
                      <div
                        className="lite-leaderboard__expanded_details_current_price"
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <BeautifulSkeleton width={80} height={20} />
                      </div>
                      <div
                        className="lite-leaderboard__expanded_details_tracked_price"
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <BeautifulSkeleton width={80} height={20} />
                      </div>
                      <div
                        className={`lite-leaderboard__expanded_details_overall_performance`}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <BeautifulSkeleton width={80} height={20} />
                      </div>
                      <div
                        className={`lite-leaderboard__expanded_details_period_performance`}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <BeautifulSkeleton width={80} height={20} />
                      </div>
                      <div
                        className="lite-leaderboard__expanded_details_allocation"
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <BeautifulSkeleton width={50} height={20} />
                      </div>
                    </div>
                  ) : (
                    <div
                      className="lite-leaderboard__expanded_details_row color-black"
                      key={index}
                    >
                      <div
                        className={`lite-leaderboard__expanded_details_ph ${isBlurred ? "blurred" : ""}`}
                      >
                        <FallbackTickerIcon
                          src={`https://assets.tradealgo.com/stock-logos/${data.ticker}.png`}
                          className="lite-leaderboard__expanded_stock_icon"
                          ticker={data.ticker}
                        />
                        <span className="font-14 font-bold">{data.ticker}</span>
                        <span className="font-14 lite-leaderboard-ticker-name">
                          {truncateText(data?.companyName, 35)}
                        </span>
                      </div>
                      <div
                        className={`lite-leaderboard__expanded_details_current_price ${isBlurred ? "blurred" : ""}`}
                      >
                        <CssPop
                          comparator={data.currentPrice}
                          ticker={data.ticker}
                        />
                      </div>
                      <div
                        className={`lite-leaderboard__expanded_details_tracked_price ${isBlurred ? "blurred" : ""}`}
                      >
                        ${data?.trackedPrice}
                      </div>
                      <div
                        className={`lite-leaderboard__expanded_details_overall_performance ${data?.overallPerformance > 0 ? "color-green" : "color-red"}`}
                      >
                        {data.overallPerformance > 0 ? "+" : ""}
                        {data.overallPerformance?.toFixed(2)}%
                      </div>
                      <div
                        className={`lite-leaderboard__expanded_details_period_performance ${data?.performance > 0 ? "color-green" : "color-red"}`}
                      >
                        {data?.performance > 0 ? "+" : ""}
                        {data.performance?.toFixed(2)}%
                      </div>
                      <div className="lite-leaderboard__expanded_details_allocation">
                        {(data.ticker_weightage * 100)?.toFixed(2)}%
                      </div>
                    </div>
                  ),
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const stateToProps = (state) => ({
  portfolioDetail: state.leaderboard.portfolioDetail,
  portfolioDetailLoading: state.leaderboard.portfolioDetailLoading,
});

const dispatchToProps = (dispatch) => ({
  loadLeaderboardDetail: (id) => dispatch(leaderboardDetailLoad(id)),
  loadPerformance: (id, period) =>
    dispatch(userPortfoliosPerformanceLoad(id, period)),
  listen: (symbols, options, disconnect, risk) =>
    dispatch(dxFeedSocketListen(symbols, options, disconnect, risk)),
});

export default connect(stateToProps, dispatchToProps)(LiteLeaderboardRow);
