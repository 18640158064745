import React from "react";
import { Tooltip } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Link } from "react-router-dom";
import { useAuth } from "../../../contexts/Auth";

const PortfolioTooltip = () => {
  const { userData } = useAuth();
  const chatName = userData?.display_name;
  return (
    <Tooltip
      arrow
      placement="right"
      title={
        <div
          className="portfolio-tooltip"
          role="dialog"
          aria-labelledby="portfolio-tooltip-title"
        >
          <div className="portfolio-tooltip-container">
            <div className="portfolio-tooltip-header">About Your Portfolio</div>
            <div className="portfolio-tooltip-description">
              Your chat username {chatName ? chatName : ""} will be displayed
              publicly on the Portfolio Leaderboard.
              <br />
              <br />
              Changes to your username can be made at any time.
            </div>
          </div>
          <Link className="portfolio-tooltip-link" to="/settings/profile">
            Change username now
          </Link>
        </div>
      }
      classes={{ tooltip: "custom-portfolio-tooltip" }}
    >
      <HelpOutlineIcon className="lite-portfolio-tooltip-icon" />
    </Tooltip>
  );
};

export default PortfolioTooltip;
